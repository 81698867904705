<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <div>
      <div v-if="favorites.length == 0 && !loading" class="text-center">
        <img src="@/assets/images/pana.svg" aspect-ratio="1.5" class="my-5" />
        <p class="font-16">{{ $t("You have not added any favorite games") }}</p>
      </div>
      <v-row v-else no-gutters style="background: black !important">
        <v-col
          v-for="(favorite, index) in favorites"
          :key="favorite._id"
          :cols="favorites.length > 3 ? 6 : 12"
          :sm="favorites.length > 1 ? 6 : 12"
        >
          <Video
            :favorite="favorite"
            @reRender="getFavorites"
            :index="index"
            :count="favorites.length"
            :key="index"
          />
          <div v-if="display" class="new">{{ name }}</div>
          <div v-if="displayWebsite" class="new">
            {{ website }}
          </div>
        </v-col>
      </v-row>
      <div class="toolBar d-flex flex-column">
        <v-icon center class="mt-1 mb-3" @click="back" color="#b6842d"
          >mdi-backburger</v-icon
        >
        <v-icon
          center
          color="white"
          @click="toggle()"
          v-if="!isFullscreen && !isIos && !loading && favorites.length != 0"
        >
          mdi-fullscreen
        </v-icon>
        <v-icon
          center
          color="white"
          @click="toggle()"
          v-if="
            isFullscreen &&
            !isMobile &&
            !isIos &&
            !loading &&
            favorites.length != 0
          "
        >
          mdi-fullscreen-exit
        </v-icon>
      </div>
    </div>
  </div>
</template>
<script>
import favoriteService from "@/services/favoriteService";
import Header from "@/components/Header.vue";
import Video from "@/components/Video";
import CircularLoader from "../components/circularLoader.vue";
import * as SocketioService from "@/services/socketioService.js";
export default {
  data() {
    return {
      favorites: [],
      zIndex: 100,
      loading: false,
      isIos: false,
      isMobile: false,
      isFullscreen: false,
      display: false,
      usernameTurn: true,
      displayWebsite: false,
      websiteTurn: false,
      name: this.$store.state.user.username,
      website: window.location.hostname,
      interval1: null,
      interval2: null,
    };
  },
  components: {
    Video,
    Header,
    CircularLoader,
  },
  async mounted() {
    this.checkIos();
    this.checkMobile();
    this.getFavorites();
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      }
    },
    async joinSockets() {
      await SocketioService.socket.joinRooms({
        roomNames: [
          "1sportsstream" + this.$store.state.user.userId,
          "streamRoom",
        ],
      });
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    },
    showUserNameService() {
      SocketioService.eventBus.$on("show-username", async () => {
        this.display = true;
        setTimeout(
          function () {
            this.display = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    showWebsiteService() {
      SocketioService.eventBus.$on("show-website", async () => {
        this.displayWebsite = true;
        setTimeout(
          function () {
            this.displayWebsite = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        await SocketioService.socket.joinRoom({ roomName: "streamRoom" });
      });
    },
    displayUsernameAndWebsite() {
      this.interval1 = setInterval(() => {
        if (this.usernameTurn) {
          this.display = true;
          setTimeout(
            function () {
              this.display = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.usernameTurn = !this.usernameTurn;
      }, 600000);
      this.interval2 = setInterval(() => {
        if (this.websiteTurn) {
          this.displayWebsite = true;
          setTimeout(
            function () {
              this.displayWebsite = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.websiteTurn = !this.websiteTurn;
      }, 600000);
    },
    async getFavorites() {
      this.loading = true;
      try {
        const result = await favoriteService.getFavoritesForUser();
        if (result.status === 200) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            result.data,
            process.env.VUE_APP_ENV_CRYPTO_KEY
          ).toString(this.$CryptoJS.enc.Utf8);
          this.favorites = JSON.parse(decrypted);
          await this.joinSockets();
          this.showUserNameService();
          this.showWebsiteService();
          this.socketReconnection();
          this.displayUsernameAndWebsite();
          this.manageFullscreen();
        }
      } catch (error) {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.exitFullscreen1();
        }
        if (
          error.response &&
          error.response.status == 404 &&
          error.response.data == "Not Subscribed"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Please Subscribe to see Favorites"),
            color: "error ",
            isVisible: true,
          });
          this.$router.push("/subscription");
        }
        console.log(error);
      }
      this.loading = false;
    },
    async back() {
      await this.$dialog
        .confirm({
          text: this.$t("Do you really want to exit?"),
          actions: {
            false: this.$t("popups.no"),
            true: this.$t("popups.yes"),
          },
        })
        .then((res) => {
          if (res == true) {
            this.$router.push("/");
          }
        });
    },
    toggle() {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        this.exitFullscreen1();
      } else {
        this.requestFullscreen1();
      }
    },
    manageFullscreen() {
      setTimeout(async () => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
          if (this.isMobile && !this.isIos) {
            await this.$dialog
              .confirm({
                text: "Enter Full-screen?",
                actions: {
                  false: this.$t("popups.no"),
                  true: this.$t("popups.yes"),
                },
              })
              .then((res) => {
                if (res == true) {
                  this.requestFullscreen1();
                }
              });
          }
        }
        window.addEventListener("fullscreenchange", (event) => {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.isFullscreen = true;
          } else {
            this.isFullscreen = false;
          }
        });
      }, 2000);
    },
    requestFullscreen1() {
      var elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen();
      }
      screen.orientation.lock("landscape-primary");
    },
    exitFullscreen1() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozRequestFullScreen) {
        document.mozExitFullScreen();
      }
      screen.orientation.unlock();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isIos) {
      next();
      window.location = window.location;
    } else {
      next();
    }
  },
};
</script>
