<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <div v-else>
      <Header :title="$t('headers.home')" :planDetails="userData.planDetails" />
      <h4 v-if="notification" class="text-center red-bg">
        {{ notification }}
      </h4>
      <h3
        v-if="userData && userData.adminMessage"
        class="text-center gold-bg black-txt py-1"
      >
        {{ userData.adminMessage + ` ${userData.username}`}}
      </h3>
      <marquee v-if="userData && userData.message" class="white-txt">{{
        userData.message
      }}</marquee>
      <div class="mx-4" style="margin-top: 12px; margin-bottom: 8px">
        <v-row>
          <v-tabs height="35px">
            <v-tabs-slider color="#b6842d"></v-tabs-slider>
            <v-col class="py-0 px-0">
              <v-tab class="font-16 py-2 px-0" href="#live">
                {{ $t("home.live") }} ({{ liveCount }})
              </v-tab>
            </v-col>
            <v-col class="py-0 px-0">
              <v-tab class="font-16 py-2 px-0" href="#upcoming">
                {{ $t("home.upcoming") }} ({{ upcomingCount }})
              </v-tab>
            </v-col>
            <v-tab-item value="live">
              <Live @countLive="countLive" />
            </v-tab-item>
            <v-tab-item value="upcoming">
              <Upcoming />
            </v-tab-item>
          </v-tabs>
        </v-row>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
import Upcoming from "../components/home/UpcomingMatch.vue";
import Live from "../components/home/LiveMatch.vue";
import Header from "../components/Header.vue";
import gameService from "@/services/gameService.js";
import * as SocketioService from "../services/socketioService.js";
import loginService from "@/services/userService.js";
import circularLoader from "@/components/circularLoader.vue";
import moment from "moment";

export default {
  name: "Home",
  components: {
    Upcoming,
    Live,
    Header,
    circularLoader,
  },
  data() {
    return {
      tabs: ["Live", "Upcoming"],
      liveCount: 0,
      upcomingCount: 0,
      loading: false,
      userData: undefined,
      notification: undefined,
    };
  },
  async created() {
    this.loading = true;
    SocketioService.eventBus.$on("update-games", () => {
      this.getUpcomingGamesCount();
    });
    this.getUpcomingGamesCount();
    try {
      const result = await loginService.getUserDetails(
        this.$store.state.user.userId
      );
      if (result.status == 200) {
        this.userData = result.data;
        if (result.data.status == "blocked") {
          this.notification =
            "Your account is blocked, kindly contact your admin";
        } else if (result.data.planDetails) {
          const date1 = moment(result.data.planDetails.endDate);
          const date2 = moment();
          const diff = date1.diff(date2, "days") + 1;
          this.userData.planDetails.diff = diff;
          if (result.data.planDetails.status == "active" && diff <= 3) {
            this.notification =
              this.$t("home.yourPlanwillExpireOn") +
              " " +
              new Date(result.data.planDetails.endDate.slice(0, -1))
                .toString("en-US", {
                  timeZone: "Asia/Kolkata",
                })
                .slice(4, 15);
          }
          if (result.data.planDetails.status == "expired") {
            this.notification =
              "Your recharge has expired, kindly contact admin to recharge";
          }
        }
        if (
          result.data.deviceType == "Android-Web" &&
          ([
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
          ].includes(navigator.platform) ||
            (navigator.userAgent.includes("Mac") && "ontouchend" in document))
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content:
              "Please login from android/web or contact admin to get Iphone account",
            color: "error",
            isVisible: true,
          });
          this.$router.push({
            path: "/login",
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.$store.commit("snackbar/setSnackbar", {
        content: "Something went wrong",
        color: "error",
        isVisible: true,
      });
      this.$router.push({
        path: "/login",
      });
    }
    this.loading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.includes("/livestream/") || to.path.includes("/favorites")) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        try {
          var elem = document.getElementById("app");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
          } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
          } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen();
          }
          screen.orientation.lock("landscape-primary");
        } catch (error) {
          console.log(error);
        }
      }
    }
    next();
  },
  methods: {
    countLive(value) {
      this.liveCount = value;
    },
    async getUpcomingGamesCount() {
      try {
        const data = {
          on1Sports: true,
        };
        const result = await gameService.getUpcomingGamesLight(data);
        if (result.status == 200) {
          this.upcomingCount = result.data.length;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notifier {
  color: white;
  font-size: 16px;
  font-weight: bolder;
}
.marginn {
  margin-top: 22px;
}
.dialog {
  position: relative;
}
.dialogButtons {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 800px) {
  .dialogButtons {
    bottom: 10px;
  }
}
@media only screen and (max-width: 500px) {
  .dialogButtons {
    bottom: 5px;
  }
}
// .cross-icon {
//   position: absolute;
//   right: 5px;
//   top: 5px
// }
</style>
