<template>
  <div>
    <div v-if="!playstarted && !favorites" class="d-flex justify-center">
      <flashLoader />
    </div>
    <div
      :id="'playerDiv' + index"
      class="videoDimensions"
      :class="{
        favorite1: favorites && count == 1,
        favorite2: favorites && count == 2,
        favorite3: favorites && count == 3,
        favorite4: favorites && count == 4,
      }"
    >
      <div v-if="displaycommentaryText" class="commentaryText">
        {{ commentaryText }}
      </div>
      <marquee v-if="messageText" class="messageText">{{
        messageText
      }}</marquee>
    </div>
  </div>
</template>

<script>
import * as SocketioService from "@/services/socketioService.js";
import flashLoader from "../components/flashLoader.vue";
import router from "../router/index";
import gameService from "@/services/gameService";
const ballStart = require("@/assets/sounds/ballStart.mp3");
const zero = require("@/assets/sounds/zero.mp3");
const one = require("@/assets/sounds/one.mp3");
const two = require("@/assets/sounds/two.mp3");
const three = require("@/assets/sounds/three.mp3");
const four = require("@/assets/sounds/four.mp3");
const six = require("@/assets/sounds/six.mp3");
const wicket = require("@/assets/sounds/wicket.mp3");
const noball = require("@/assets/sounds/noball.mp3");
const wide = require("@/assets/sounds/wide.mp3");
const freehit = require("@/assets/sounds/freehit.mp3");
const playersAreComing = require("@/assets/sounds/playersAreComing.mp3");
const heavyRain = require("@/assets/sounds/heavyRain.mp3");
const rain = require("@/assets/sounds/rain.mp3");
const coversAreComing = require("@/assets/sounds/coversAreComing.mp3");
const batsmanInjured = require("@/assets/sounds/batsmanInjured.mp3");
// const bowlerInjured = require("@/assets/sounds/bowlerInjured.mp3");
const appeal = require("@/assets/sounds/appeal.mp3");
const thirdUmpire = require("@/assets/sounds/thirdUmpire.mp3");
const notOut = require("@/assets/sounds/notout.mp3");
const inTheAir = require("@/assets/sounds/ballInTheAir.mp3");
const bowlerStopped = require("@/assets/sounds/bowlerStopped.mp3");
const checking = require("@/assets/sounds/checking.mp3");
const fastBowler = require("@/assets/sounds/fastBowler.mp3");
const spinBowler = require("@/assets/sounds/spinner.mp3");
const breakSound = require("@/assets/sounds/break.mp3");
const review = require("@/assets/sounds/review.mp3");
const overthrow = require("@/assets/sounds/overthrow.mp3");
// const catchDrop = require("@/assets/sounds/catchDrop.mp3");
const commenatry = require("@/assets/sounds/commentary.mp3");
export default {
  name: "NanoPlayer",
  props: ["index", "streams", "favorites", "count", "id", "message"],
  components: {
    flashLoader,
  },
  data() {
    return {
      nanoPlayer: undefined,
      gameId: undefined,
      commentaryText: "",
      messageText: this.message,
      displaycommentaryText: false,
      audio: null,
      mute: false,
      playstarted: false,
      isIos: false,
      config: {
        source: {
          defaults: {
            service: "bintu",
          },
          entries: [
            {
              index: 0,
              h5live: {
                rtmp: {
                  streamname: this.streams[0].streamname,
                },
                server: {
                  websocket:
                    "wss://bintu-h5live-secure.nanocosmos.de/h5live/authstream",
                  hls: "https://bintu-h5live-secure.nanocosmos.de/h5live/authhttp/playlist.m3u8",
                  progressive:
                    "https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4",
                },
                security: JSON.parse(
                  this.CryptoJS.enc.Utf8.stringify(
                    this.$CryptoJS.AES.decrypt(
                      this.streams[0].security,
                      process.env.VUE_APP_ENV_CRYPTO_KEY,
                      {
                        keySize: 128 / 8,
                        iv: process.env.VUE_APP_ENV_CRYPTO_KEY,
                        mode: this.CryptoJS.mode.CBC,
                        padding: this.CryptoJS.pad.Pkcs7,
                      }
                    )
                  )
                ),
              },
            },
            {
              index: 1,
              h5live: {
                rtmp: {
                  streamname: this.streams[1].streamname,
                },
                server: {
                  websocket:
                    "wss://bintu-h5live-secure.nanocosmos.de/h5live/authstream",
                  hls: "https://bintu-h5live-secure.nanocosmos.de/h5live/authhttp/playlist.m3u8",
                  progressive:
                    "https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4",
                },
                security: JSON.parse(
                  this.CryptoJS.enc.Utf8.stringify(
                    this.$CryptoJS.AES.decrypt(
                      this.streams[1].security,
                      process.env.VUE_APP_ENV_CRYPTO_KEY,
                      {
                        keySize: 128 / 8,
                        iv: process.env.VUE_APP_ENV_CRYPTO_KEY,
                        mode: this.CryptoJS.mode.CBC,
                        padding: this.CryptoJS.pad.Pkcs7,
                      }
                    )
                  )
                ),
              },
            },
            {
              index: 2,
              h5live: {
                rtmp: {
                  streamname: this.streams[2].streamname,
                },
                server: {
                  websocket:
                    "wss://bintu-h5live-secure.nanocosmos.de/h5live/authstream",
                  hls: "https://bintu-h5live-secure.nanocosmos.de/h5live/authhttp/playlist.m3u8",
                  progressive:
                    "https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4",
                },
                security: JSON.parse(
                  this.CryptoJS.enc.Utf8.stringify(
                    this.$CryptoJS.AES.decrypt(
                      this.streams[2].security,
                      process.env.VUE_APP_ENV_CRYPTO_KEY,
                      {
                        keySize: 128 / 8,
                        iv: process.env.VUE_APP_ENV_CRYPTO_KEY,
                        mode: this.CryptoJS.mode.CBC,
                        padding: this.CryptoJS.pad.Pkcs7,
                      }
                    )
                  )
                ),
              },
            },
            {
              index: 3,
              h5live: {
                rtmp: {
                  streamname: this.streams[3].streamname,
                },
                server: {
                  websocket:
                    "wss://bintu-h5live-secure.nanocosmos.de/h5live/authstream",
                  hls: "https://bintu-h5live-secure.nanocosmos.de/h5live/authhttp/playlist.m3u8",
                  progressive:
                    "https://bintu-h5live.nanocosmos.de:443/h5live/http/stream.mp4",
                },
                security: JSON.parse(
                  this.CryptoJS.enc.Utf8.stringify(
                    this.$CryptoJS.AES.decrypt(
                      this.streams[3].security,
                      process.env.VUE_APP_ENV_CRYPTO_KEY,
                      {
                        keySize: 128 / 8,
                        iv: process.env.VUE_APP_ENV_CRYPTO_KEY,
                        mode: this.CryptoJS.mode.CBC,
                        padding: this.CryptoJS.pad.Pkcs7,
                      }
                    )
                  )
                ),
              },
            },
          ],
          options: {
            adaption: {
              rule: "deviationOfMean2",
            },
            switch: {
              method: "server",
              pauseOnError: false,
              forcePlay: true,
              fastStart: false,
              timeout: 20,
            },
          },
          startIndex: 0,
        },
        playback: {
          autoplay: true,
          automute: true,
          muted: this.favorites ? true : false,
          latencyControlMode: "fastadaptive",
          allowSafariHlsFallback: true,
          forceTech: this.isIos ? "hls.native" : "h5live",
          metadata: true,
          metadataLowDelay: this.isIos ? true : false,
        },
        events: {
          onWarning: function (e) {
            console.log(e);
          },
          onPlay: async (data) => {
            this.playstarted = true;
            console.log("PLAY STARTED", data);
          },
          onLoading: (e) => {
            this.playstarted = false;
            console.log(e);
          },
          onStartBuffering: function (e) {
            this.playstarted = false;
            console.log(e);
          },
          onStopBuffering: async (e) => {
            this.playstarted = true;
            console.log(e);
          },
          onMetaData: function (e) {
            console.log(e);
          },
          onDestroy: function (e) {
            console.log(e);
          },
          onUpdateSourceInit: function (e) {
            console.log(e);
          },
          onUpdateSourceSuccess: function (e) {
            console.log(e);
          },
          onUpdateSourceFail: function (e) {
            console.log(e);
          },
          onMute: (e) => {
            this.mute = true;
            console.log(e);
          },
          onUnmute: (e) => {
            this.mute = false;
            for (let i = 0; i < this.count; i++) {
              try {
                if (
                  i !== this.index &&
                  document.getElementById("playerDiv" + i) &&
                  !document.getElementById("playerDiv" + i).muted
                ) {
                  document.getElementById("playerDiv" + i).muted = true;
                }
                if (
                  i !== this.index &&
                  document.getElementById("videoControls" + i) &&
                  !document.getElementById("videoControls" + i).muted
                ) {
                  document.getElementById("videoControls" + i).muted = true;
                }
              } catch (error) {
                console.log(error);
              }
              try {
                if (
                  i !== this.index &&
                  document.getElementById("h5live-playerDiv" + i) &&
                  !document.getElementById("h5live-playerDiv" + i).muted
                ) {
                  document.getElementById("h5live-playerDiv" + i).muted = true;
                }
              } catch (error) {
                console.log(error);
              }
            }
            console.log(e);
          },
          onUpdateSourceAbort: function (e) {
            console.log(e);
          },
          onError: function (e) {
            this.playstarted = true;
            console.log("Error:", e);
          },
        },
        style: {
          fullScreenControl: false,
          displayMutedAutoplay: false,
        },
        metrics: {
          accountId: process.env.VUE_APP_ENV_METRICS_ID,
          accountKey: process.env.VUE_APP_ENV_METRICS_KEY,
          userId: this.$store.state.user.userId,
          eventId: "event1",
          statsInterval: 10,
          customField1: this.$store.state.user.username,
        },
      },
    };
  },
  async mounted() {
    this.checkIos();
    this.startPlay();
    this.gameId = this.$route.params.id;
    this.manageFullScreen();
    await this.joinSockets();
    await this.commentaryService();
    await this.logOutGame();
    await this.gameLogsJoin();
    await this.updateGamesService();
    await this.socketReconnection();
  },
  methods: {
    async joinSockets() {
      if (this.favorites) {
        await SocketioService.socket.joinRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.joinRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
    async gameLogsJoin() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.$route.params.id,
          event: "join",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async gameLogsLeft() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.gameId,
          event: "left",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async commentaryService() {
      SocketioService.eventBus.$on("commentary", async (data) => {
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.text && data.com !== "Clear") {
            this.displaycommentaryText = true;
            this.commentaryText = data.com;
          } else {
            this.commentaryText = "";
            this.displaycommentaryText = false;
          }
          if (data.sound && !this.mute) {
            if (this.audio) {
              this.audio.pause();
            }
            if (data.com == "0 Runs") this.audio = new Audio(zero);
            else if (data.com == "1 Run") this.audio = new Audio(one);
            else if (data.com == "2 Runs") this.audio = new Audio(two);
            else if (data.com == "3 Runs") this.audio = new Audio(three);
            else if (data.com == "4 Runs") this.audio = new Audio(four);
            else if (data.com == "6 Runs") this.audio = new Audio(six);
            else if (data.com == "Wicket") this.audio = new Audio(wicket);
            else if (data.com == "No Ball") this.audio = new Audio(noball);
            else if (data.com == "Wide") this.audio = new Audio(wide);
            else if (data.com == "Free Hit") this.audio = new Audio(freehit);
            else if (data.com == "Ball Start")
              this.audio = new Audio(ballStart);
            else if (data.com == "Players Are Coming")
              this.audio = new Audio(playersAreComing);
            else if (data.com == "Heavy Rain")
              this.audio = new Audio(heavyRain);
            else if (data.com == "Rain") this.audio = new Audio(rain);
            else if (data.com == "Covers Are Coming")
              this.audio = new Audio(coversAreComing);
            else if (data.com == "Batsman Injured")
              this.audio = new Audio(batsmanInjured);
            // else if (data.com == "Bowler Injured")
            //   this.audio = new Audio(bowlerInjured);
            else if (data.com == "Appeal") this.audio = new Audio(appeal);
            else if (data.com == "Third Umpire")
              this.audio = new Audio(thirdUmpire);
            else if (data.com == "Not Out") this.audio = new Audio(notOut);
            else if (data.com == "In The Air") this.audio = new Audio(inTheAir);
            else if (data.com == "Bowler Stop")
              this.audio = new Audio(bowlerStopped);
            else if (data.com == "Checking") this.audio = new Audio(checking);
            else if (data.com == "Fast Bowler")
              this.audio = new Audio(fastBowler);
            else if (data.com == "Spin Bowler")
              this.audio = new Audio(spinBowler);
            else if (data.com == "Break") this.audio = new Audio(breakSound);
            else if (data.com == "Review") this.audio = new Audio(review);
            else if (data.com == "Overthrow") this.audio = new Audio(overthrow);
            // else if (data.com == "Catch Drop")
            //   this.audio = new Audio(catchDrop);
            else if (data.com == "Commentary")
              this.audio = new Audio(commenatry);
            else if (data.com == "Clear") this.audio = new Audio("");
            else {
              return;
            }
            if (
              data.com !== "Clear" &&
              data.com !== "Sorry" &&
              data.com !== "Good Night" &&
              data.com !== "Match Delayed" &&
              data.com !== "Wet Outfield"
            )
              this.audio.play();
          }
          // https://sportstv365-files.s3.ap-south-1.amazonaws.com/clearWeather.mp3
        }
      });
    },
    async logOutGame() {
      SocketioService.eventBus.$on("logOut", async () => {
        router.push("/");
      });
    },
    async updateGamesService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.message) {
            this.messageText = data.message;
          }
          if (data.message == "") {
            this.messageText = "";
          }
        }
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinSockets();
      });
    },
    startPlay() {
      this.nanoPlayer = new NanoPlayer("playerDiv" + this.index);
      this.nanoPlayer.setup(this.config).then(
        function () {
          console.log("setup success");
        },
        async (error) => {
          this.playstarted = true;
          console.log(error);
          if (
            error ==
            "Error: The players source configuration is malformed or missing, please check documentation for more information or contact us."
          ) {
            await this.$dialog
              .confirm({
                text: this.$t(
                  "Use Chrome/Safari browser for better experience"
                ),
                actions: {
                  true: "Okay",
                },
              })
              .then(() => {
                this.nanoPlayer.destroy();
                this.$router.push("/");
              });
          }
        }
      );
    },
    manageFullScreen() {
      if (!this.favorites) {
        window.addEventListener("fullscreenchange", (event) => {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.isFullscreen = true;
          } else {
            this.isFullscreen = false;
          }
        });
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
        }
      }
    },
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    async leaveSockets() {
      if (this.favorites) {
        await SocketioService.socket.leaveRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.leaveRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
  },
  beforeDestroy() {
    this.gameLogsLeft();
    this.leaveSockets();
    this.nanoPlayer.destroy();
  },
  watch: {
    mute: {
      async handler() {
        if (this.audio) {
          if (this.mute) {
            this.audio.muted = true;
          } else {
            this.audio.muted = false;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss">
.videoDimensions {
  width: 100% !important;
  height: 100vh !important;
}
.favorite1 {
  width: 100% !important;
  height: 100vh !important;
}
.favorite2 {
  width: 100% !important;
  height: 50vh !important;
}
.favorite3 {
  width: 100% !important;
  height: 33vh !important;
}
.favorite4 {
  width: 100% !important;
  height: 50vh !important;
}
@media only screen and (min-width: 600px) {
  .favorite2 {
    width: 100% !important;
    height: 100vh !important;
  }
  .favorite3 {
    width: 100% !important;
    height: 50vh !important;
  }
}
</style>
<style lang="scss">
[id^="middleView-playerDiv"],
[id^="middleView-playerDiv" i],
[id*="middleView-playerDiv"],
[id*="middleView-playerDiv" i],
[id^="controls-PlayerDiv"],
[id^="controls-PlayerDiv" i],
[id*="controls-PlayerDiv"],
[id*="controls-PlayerDiv" i] {
  z-index: 1000 !important;
}
.new {
  color: white !important;
  font-weight: 400;
  position: absolute;
  z-index: 1000000000 !important;
  left: 3%;
  top: 3%;
  color: white;
  animation: 15s moving-watermark infinite;
}
.commentaryText {
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  position: absolute;
  z-index: 1000 !important;
  top: 0px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  left: 50%;
  padding: 0 15px;
  transform: translate(-50%, 0);
  text-align: center;
}
.messageText {
  background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  position: absolute;
  z-index: 1000 !important;
  bottom: 80px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  // text-align: center;
}
@media only screen and (max-width: 500px) {
  .commentaryText {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0 10px;
  }
  .messageText {
    font-weight: 700;
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 350px) {
  .commentaryText {
    font-weight: 700;
    font-size: 1rem;
    padding: 0 2px;
  }
  .messageText {
    font-weight: 700;
    font-size: 0.7rem;
  }
}
@keyframes moving-watermark {
  0% {
    left: 0%;
    top: 3%;
  }
  25% {
    left: 35%;
    top: 93%;
  }
  50% {
    left: 70%;
    top: 3%;
  }
  75% {
    left: 70%;
    top: 93%;
  }
  100% {
    left: 0%;
    top: 3%;
  }
}
@media only screen and (max-width: 600px) {
  @keyframes moving-watermark {
    0% {
      left: 0%;
      top: 3%;
    }
    25% {
      left: 20%;
      top: 93%;
    }
    50% {
      left: 40%;
      top: 3%;
    }
    75% {
      left: 40%;
      top: 93%;
    }
    100% {
      left: 0%;
      top: 3%;
    }
  }
}
[id^="time-controls-player"],
[id^="time-controls-player" i],
[id*="time-controls-player"],
[id*="time-controls-player" i] {
  visibility: hidden;
}
[id^="loading-playerDiv"],
[id^="loading-playerDiv" i],
[id*="loading-playerDiv"],
[id*="loading-playerDiv" i] {
  visibility: hidden;
}
</style>
