<template>
  <div>
    <Header title="Password" />
    <div class="justify-center pt-6" align="center">
      <div class="input-field mx-6">
        <div class="mt-3 password">
          <v-text-field
            v-model="password"
            dense
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :label="$t('placeholder.newPassword')"
            :error-messages="passwordErrors"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          >
          </v-text-field>
        </div>

        <div class="mt-3 password">
          <v-text-field
            v-model="confirmPassword"
            dense
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            @click:append="show3 = !show3"
            :label="$t('placeholder.confirmPassword')"
            :error-messages="confirmPasswordErrors"
            required
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
          >
          </v-text-field>
        </div>
        <div class="py-7 text-center">
          <v-btn class="font-14 gold-btn py-5 black-txt" @click="submit()">{{
            $t("changepassword.submit")
          }}</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Header from "@/components/Header.vue";
import userService from "@/services/userService";
export default {
  components: {
    Header,
  },
  mixins: [validationMixin],
  data() {
    return {
      password: "",
      confirmPassword: "",
      show2: false,
      show3: false,
      zIndex: 100,
    };
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(24),
      minLength: minLength(4),
    },
    confirmPassword: {
      required,
      validateConfirmPassword(value) {
        return value === this.password;
      },
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push(this.$t("validation.passwordMost"));
      !this.$v.password.minLength &&
        errors.push(this.$t("validation.passwordLeast"));
      !this.$v.password.required &&
        errors.push(this.$t("validation.passwordRequired"));
      return errors;
    },
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.validateConfirmPassword &&
        errors.push(this.$t("validation.passwordMatch"));
      !this.$v.confirmPassword.required &&
        errors.push(this.$t("validation.confirmPasswordRequired"));
      return errors;
    },
  },
  methods: {
    async submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        const data = {
          password: this.password,
        };
        const result = await userService.resetPassword(data);
        if (result.status === 200) {
          this.$router.push("/");
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Password changed successfully"),
            color: "success",
            isVisible: true,
          });
        }
      } catch (e) {
        this.$store.commit("snackbar/setSnackbar", {
          content: "Something went wrong",
          color: "error ",
          isVisible: true,
        });
        console.log(e);
      }
    },
  },
};
</script>
<style scoped></style>
