import { io } from "socket.io-client";
import Vue from "vue";
export const eventBus = new Vue();
class SocketioService {
  socket;
  constructor() {}
  setupSocketConnection() {
    this.socket = io(process.env.VUE_APP_ENV_SOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 500,
      maxReconnectionAttempts: Infinity,
    });
    this.socket.on("disconnect", () => {
      let inter = setInterval(() => {
        if (!this.socket.connected) {
          this.socket.connect();
        } else {
          eventBus.$emit("reconnected");
          clearInterval(inter);
        }
      }, 1500);
    });
    this.socket.on("leave-connection", (data) => {
      eventBus.$emit("connection-left", data);
    });
    this.socket.on("show-username", (data) => {
      eventBus.$emit("show-username", data);
    });
    this.socket.on("show-website", (data) => {
      eventBus.$emit("show-website", data);
    });
    this.socket.on("commentary", (data) => {
      eventBus.$emit("commentary", data);
    });
    this.socket.on("logOut", (data) => {
      eventBus.$emit("logOut", data);
    });
    this.socket.on("updateGameData", (data) => {
      eventBus.$emit("updateGameData", data);
    });
    this.socket.on("block-connection", (data) => {
      eventBus.$emit("connection-blocked", data);
    });
    this.socket.on("update-games", () => {
      eventBus.$emit("update-games");
    });
    this.socket.onAny((event, ...args) => {
      eventBus.$emit("oddsData", { event: event, args: args });
    });
  }
  disconnect() {
    this.socket.connect();
  }
  joinRoom(data) {
    if (this.socket) {
      this.socket.emit("joinRoom", data);
    }
  }
  joinRooms(data) {
    if (this.socket) {
      this.socket.emit("joinRooms", data);
    }
  }
  leaveRoom(data) {
    if (this.socket) {
      this.socket.emit("leaveRoom", data);
    }
  }
  leaveRooms(data) {
    if (this.socket) {
      this.socket.emit("leaveRooms", data);
    }
  }
  streamRoom(data) {
    if (this.socket) {
      this.socket.emit("streamRoom", data);
    }
  }
}

export const socket = new SocketioService();
