<template>
  <img class="blink" src="../assets/images/logo.png" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blink {
  position: absolute;
  animation: blink 1s steps(1, end) infinite;
  z-index: 100 !important;
  top: 45%;
  transform: translate(0px, -65%);
  width: 95px;
  height: 60px;
  display: block;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
