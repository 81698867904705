<template>
  <div style="position: relative">
    <stream
      v-if="favorite.playerType == 'nanocosmos'"
      :index="index"
      :streams="favorite.streams"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
      :message="favorite.message"
    />
    <antmedia-player
      v-if="favorite.playerType == 'antmedia'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
    />
    <new-player
      v-if="favorite.playerType == 'new'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
    />
    <livetv-player
      v-if="favorite.playerType == 'livetv'"
      :index="index"
      :streams="favorite"
      :favorites="true"
      :count="count"
      :key="index"
      :id="favorite._id"
    />
    <div v-if="marketsVisible">
      <div class="oddsText">
        <div v-if="initialOddsLoaded">
          <div class="oddsHead mb-1">
            <span class="alignCenter"
              ><span class="black-transparent-patch">Match Odds</span></span
            >
          </div>
          <!-- <div class="d-flex justify-end">
            <span class="oddSubs">Back</span> <span class="oddSubs">Lay</span>
          </div> -->
          <div v-for="(odds, index) in oddsData" :key="index">
            <div class="d-flex align-center justify-end mt-1">
              <span
                class="black-transparent-patch"
                style="margin-right: -4px"
                v-if="
                  odds &&
                  odds.runner &&
                  !odds.runner.toUpperCase().includes('DRAW')
                "
                >{{ odds.runner | teamNames }}</span
              >
              <span
                class="black-transparent-patch"
                style="margin-right: -4px"
                v-else-if="odds && odds.runner"
                >{{ odds.runner }}</span
              >
              <div class="d-flex">
                <odds-box
                  v-if="odds && odds.backPrice1 && odds.backSize1"
                  :odds.sync="odds.backPrice1"
                  :volume.sync="odds.backSize1"
                  :color="'rgb(114, 187, 239)'"
                />
                <odds-box v-else :odds="0" :volume="0" />
                <odds-box
                  v-if="odds && odds.layPrice1 && odds.laySize1"
                  :odds.sync="odds.layPrice1"
                  :volume.sync="odds.laySize1"
                  :color="'rgb(249, 148, 186)'"
                />
                <odds-box v-else :odds="0" :volume="0" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="initialBookMakerLoaded" class="mt-1">
          <div class="oddsHead">
            <span class="alignCenter"
              ><span class="black-transparent-patch">{{
                "Bookmaker"
              }}</span></span
            >
          </div>
          <!-- <div class="d-flex justify-end">
            <span class="oddSubs">Back</span> <span class="oddSubs">Lay</span>
          </div> -->
          <div
            v-for="data in bookMakerData"
            :key="data.id"
            style="margin-top: -5px"
          >
            <div
              class="d-flex align-center justify-end mt-2"
              v-if="!data.suspended && !data.ballrunning"
            >
              <span
                class="black-transparent-patch"
                v-if="!data.runner.toUpperCase().includes('DRAW')"
                >{{ data.runner | teamNames }}</span
              >
              <span class="black-transparent-patch" v-else>{{
                data.runner
              }}</span>
              <!-- <div v-if="bookMarketStatus == 'Disable'">
                  <oddsMessage-box message="Market Suspended" />
                </div>
                <div v-else-if="data.status == 'Disable'">
                  <oddsMessage-box message="Suspended" />
                </div> -->
              <div class="d-flex">
                <odds-box
                  v-if="data && data.back"
                  :odds.sync="data.back"
                  :color="'rgb(114, 187, 239)'"
                />
                <odds-box v-else :odds="0" />
                <odds-box
                  v-if="data && data.lay"
                  :odds.sync="data.lay"
                  :color="'rgb(249, 148, 186)'"
                />
                <odds-box v-else :odds="0" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="initialSessionsLoaded && typeof sessions === 'object'"
        class="sessionsText"
      >
        <div
          class="oddsHead mb-1"
          style="justify-content: flex-start !important"
        >
          <span class="alignCenter"
            ><span class="black-transparent-patch">Fancy Markets</span></span
          >
        </div>
        <!-- <div class="d-flex">
          <span class="oddSubs">No</span> <span class="oddSubs">Yes</span>
        </div> -->
        <div v-for="item in sessions" :key="item._id">
          <div
            class="d-flex align-center mt-1"
            v-if="!item.ball_running && !item.ballrunning && !item.suspended"
          >
            <!-- <div v-if="item.isMarketSuspended">
              <oddsMessage-box :favorites="true" message="Market Suspended" />
            </div>
            <div v-else-if="item.ballChalu">
              <oddsMessage-box :favorites="true" message="Bowl Running" />
            </div>
            <div v-else-if="item.isSuspended">
              <oddsMessage-box :favorites="true" message="Suspended" />
            </div> -->
            <div class="d-flex">
              <odds-box
                :favorites="true"
                :odds.sync="item.no"
                :volume.sync="item.no_rate"
                :color="'rgb(249, 148, 186)'"
                :fancy="true"
              />
              <odds-box
                :favorites="true"
                :odds.sync="item.yes"
                :volume.sync="item.yes_rate"
                :color="'rgb(114, 187, 239)'"
                :fancy="true"
              />
            </div>
            <span class="black-transparent-patch">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        initialOddsLoaded || initialBookMakerLoaded || initialSessionsLoaded
      "
      class="oddsVisibility"
    >
      <v-icon
        color="#b6842d"
        v-if="marketsVisible"
        dark
        @click="marketsVisible = !marketsVisible"
      >
        mdi-eye
      </v-icon>
      <v-icon color="red" v-else dark @click="marketsVisible = !marketsVisible">
        mdi-eye-off
      </v-icon>
    </div>
  </div>
</template>
<script>
import stream from "@/components/NanoPlayer.vue";
import AntmediaPlayer from "../components/AntmediaPlayer.vue";
// import * as playSocketService from "@/services/playSocketService.js";
import * as SocketioService from "@/services/socketioService.js";
import oddsBox from "@/components/oddsBox.vue";
import oddsMessageBox from "@/components/oddsMessageBox.vue";
import NewPlayer from "../components/NewPlayer.vue";
import LivetvPlayer from "./LiveTvPlayer.vue";
export default {
  components: {
    stream,
    AntmediaPlayer,
    oddsBox,
    oddsMessageBox,
    NewPlayer,
    LivetvPlayer,
  },
  props: ["favorite", "index", "count"],
  data() {
    return {
      eventId: this.favorite.eventId,
      // marketId: undefined,
      // sessionId: undefined,
      // bookMakerId: undefined,
      oddsData: [],
      bookMakerData: [],
      // bookMakerSuspened: false,
      // bookMakerBallRunning: false,
      sessions: [],
      initialOddsLoaded: false,
      initialSessionsLoaded: false,
      initialBookMakerLoaded: false,
      marketsVisible: true,
      showTooltip: true,
      tooltipTimer: undefined,
    };
  },
  async created() {
    await this.OddsManage();
    await this.oddsUpdateService();
    await this.gameUpdateService();
    this.socketReconnection();
    this.hideTooltip();
  },
  methods: {
    async joinOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.joinRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
    async socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinOddsSockets();
      });
      // SocketioService.eventBus.$on("reconnectedPlaySocket", async () => {
      //   console.log("reconnected");
      //   this.joinPlaySockets();
      // });
    },
    async oddsUpdateService() {
      SocketioService.eventBus.$on("oddsData", async (data) => {
        if (
          data.event == this.eventId &&
          data.args &&
          data.args.length > 0 &&
          data.args[0] &&
          data.args[0].odds &&
          data.args[0].odds.length > 0
        ) {
          this.assignOdds(data.args[0].odds);
        }
        if (
          data.event == this.eventId + "-FM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          if (
            data.event == this.eventId + "-FM" &&
            data.args &&
            data.args.length > 0 &&
            data.args[0]
          ) {
            if (data.args[0].fancyData && data.args[0].fancyData.length > 0) {
              this.sessions = data.args[0].fancyData;
              console.log(data.args, "SESSIONS");
              this.initialSessionsLoaded = true;
            } else {
              this.initialSessionsLoaded = false;
            }
          }
        }
        if (
          data.event == this.eventId + "-BM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          this.assignBookMaker(data.args[0].runners);
        }
      });
    },
    async gameUpdateService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          data.eventId != this.eventId && (data.id == this.$route.params.id || data.id == this.id)
        ) {
          if (this.eventId)
            await SocketioService.socket.leaveRooms({
              roomNames: [
                this.eventId,
                this.eventId + "-BM",
                this.eventId + "-FM",
              ],
            });
          this.eventId = data.eventId;
          this.OddsManage();
        }
      });
    },
    async OddsManage() {
      console.log("odds manage", this.eventId);
      if (this.eventId) {
        await this.joinOddsSockets();
      }
    },
    // async getOddsFromApi() {
    //   try {
    //     const obj = {
    //       accessLevel: "Player",
    //       game: "Cricket",
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getOddsPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignOdds(result.data.data[0].runners);
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getSessionsFromApi() {
    //   try {
    //     const obj = {
    //       market: this.sessionId,
    //     };
    //     const result = await playOddsService.getSessionsPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       this.initialSessionsLoaded = true;
    //       this.sessions = result.data;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getBookMakerFromApi() {
    //   try {
    //     const obj = {
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getBookMakerPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignBookMaker(
    //           result.data.data[0].runners,
    //           result.data.data[0].marketStatus
    //         );
    //         this.bookMakerId = result.data.data[0].betfairId;
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    assignOdds(data) {
      this.oddsData = data;
      this.initialOddsLoaded = true;
    },
    assignBookMaker(data) {
      this.bookMakerData = data;
      // if (suspended == "1") this.bookMakerSuspened = true;
      // else this.bookMakerSuspened = false;
      // if (ballrunning == "1") this.bookMakerBallRunning = true;
      // else this.bookMakerBallRunning = false;
      this.initialBookMakerLoaded = true;
    },
    async leaveOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.leaveRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
    hideTooltip() {
      if (this.tooltipTimer) clearTimeout(this.tooltipTimer);
      this.tooltipTimer = setTimeout(
        function () {
          this.showTooltip = false;
        }.bind(this),
        1500
      );
    },
  },
  async beforeDestroy() {
    if (this.tooltipTimer) {
      clearTimeout(this.tooltipTimer);
    }
    await this.leaveOddsSockets();
  },
};
</script>
<style scoped>
.favorite-img {
  position: relative;
}
.hart-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.hart-btn {
  opacity: 0.5;
}
.mar {
  margin-right: 10px;
}
.oddsVisibility {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
.oddsText {
  color: white !important;
  position: absolute;
  top: 30px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.45rem;
  right: 0;
  /* transform: translate(0, -50%); */
  z-index: 700;
}
.sessionsText {
  color: white !important;
  position: absolute;
  top: 15px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.45rem;
  left: 0;
  /* transform: translate(0, -50%); */
  z-index: 140;
}
.oddsHead {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  font-size: 0.45rem;
  font-weight: bold;
}
/* .oddSubs {
  min-width: 30px;
  text-align: center;
  margin-left: 2.5px;
} */
.alignCenter {
  text-align: center;
  width: 100px;
}
.livetvCentre {
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (min-width: 1000px) {
  .oddSubs {
    min-width: 40px;
  }
  .alignCenter {
    width: 90px;
  }
  .oddsText {
    font-size: 0.6rem;
  }
  .sessionsText {
    font-size: 0.6rem;
  }
  /* .oddsHead {
    font-size: 0.6rem;
  } */
}
@media only screen and (max-width: 700px) {
  .oddsText {
    font-size: 0.3rem;
  }
  /* .oddSubs {
    min-width: 15px;
  } */
  .sessionsText {
    font-size: 0.3rem;
  }
  .oddsHead {
    font-size: 0.3rem;
  }
  .alignCenter {
    width: 50px;
  }
}
</style>
