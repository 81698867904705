<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <circularLoader />
    </div>
    <div v-if="loaded">
      <div class="toolBar d-flex flex-column" style="bottom: 180px !important">
        <v-icon center class="mt-1 mb-3" @click="back" color="#b6842d"
          >mdi-backburger</v-icon
        >
        <v-icon
          center
          class="mb-3"
          color="white"
          @click="toggle()"
          v-if="
            !isFullscreen && !isIos && liveStreamDetails.playerType != 'new'
          "
        >
          mdi-fullscreen
        </v-icon>
        <v-icon
          center
          class="mb-3"
          color="white"
          @click="toggle()"
          v-if="
            isFullscreen &&
            !isMobile &&
            !isIos &&
            liveStreamDetails.playerType != 'new'
          "
        >
          mdi-fullscreen-exit
        </v-icon>
        <div
          v-if="
            initialOddsLoaded || initialBookMakerLoaded || initialSessionsLoaded
          "
        >
          <v-tooltip right v-model="showTooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#b6842d"
                v-if="marketsVisible"
                dark
                v-bind="attrs"
                v-on="on"
                @click="
                  {
                    marketsVisible = !marketsVisible;
                    showTooltip = true;
                    hideTooltip();
                  }
                "
              >
                mdi-eye
              </v-icon>
              <v-icon
                color="red"
                v-else
                dark
                v-bind="attrs"
                v-on="on"
                @click="
                  {
                    marketsVisible = !marketsVisible;
                    showTooltip = true;
                    hideTooltip();
                  }
                "
              >
                mdi-eye-off
              </v-icon>
            </template>
            <span>{{
              marketsVisible ? "Click to hide odds" : "Click to view odds"
            }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-if="marketsVisible">
        <div class="oddsText">
          <div v-if="initialOddsLoaded">
            <div class="text-center oddsHead mb-1">
              <span class="alignCenter"
                ><span class="black-transparent-patch">Match Odds</span></span
              >
            </div>
            <div v-for="(odds, index) in oddsData" :key="index">
              <div class="d-flex align-center justify-end mt-2">
                <span
                  class="black-transparent-patch"
                  v-if="
                    odds &&
                    odds.runner &&
                    !odds.runner.toUpperCase().includes('DRAW')
                  "
                  style="margin-right: -4px"
                  >{{ odds.runner | teamNames }}</span
                >
                <span
                  class="black-transparent-patch"
                  v-else-if="odds && odds.runner"
                  style="margin-right: -4px"
                  >{{ odds.runner }}</span
                >
                <div class="d-flex">
                  <odds-box
                    v-if="odds && odds.backPrice1 && odds.backSize1"
                    :odds.sync="odds.backPrice1"
                    :volume.sync="odds.backSize1"
                    :color="'rgb(114, 187, 239)'"
                  />
                  <odds-box v-else :odds="0" :volume="0" />
                  <odds-box
                    v-if="odds && odds.layPrice1 && odds.laySize1"
                    :odds.sync="odds.layPrice1"
                    :volume.sync="odds.laySize1"
                    :color="'rgb(249, 148, 186)'"
                  />
                  <odds-box v-else :odds="0" :volume="0" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="initialBookMakerLoaded" class="mt-2">
            <div class="text-center oddsHead">
              <span class="alignCenter"
                ><span class="black-transparent-patch">{{
                  "Bookmaker"
                }}</span></span
              >
            </div>
            <!-- <div class="d-flex justify-end">
              <span class="oddSubs">Back</span> <span class="oddSubs">Lay</span>
            </div> -->
            <div
              v-for="(data, index) in bookMakerData"
              :key="index"
              style="margin-top: -5px"
            >
              <div
                class="d-flex align-center justify-end mt-2"
                v-if="!data.suspended && !data.ballrunning"
              >
                <span
                  class="black-transparent-patch"
                  v-if="!data.runner.toUpperCase().includes('DRAW')"
                  >{{ data.runner | teamNames }}</span
                >
                <span class="black-transparent-patch" v-else>{{
                  data.runner
                }}</span>
                <!-- <div v-if="bookMarketStatus == 'Disable'">
                  <oddsMessage-box message="Market Suspended" />
                </div>
                <div v-else-if="data.status == 'Disable'">
                  <oddsMessage-box message="Suspended" />
                </div> -->
                <div class="d-flex">
                  <odds-box
                    v-if="data && data.back"
                    :odds.sync="data.back"
                    :color="'rgb(114, 187, 239)'"
                  />
                  <odds-box v-else :odds="0" />
                  <odds-box
                    v-if="data && data.lay"
                    :odds.sync="data.lay"
                    :color="'rgb(249, 148, 186)'"
                  />
                  <odds-box v-else :odds="0" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="initialSessionsLoaded && typeof sessions === 'object'"
          class="sessionsText"
        >
          <div
            class="text-center oddsHead mb-1"
            style="justify-content: flex-start !important"
          >
            <span class="alignCenter"
              ><span class="black-transparent-patch">Fancy Markets </span></span
            >
          </div>
          <!-- <div class="d-flex">
            <span class="oddSubs">No</span> <span class="oddSubs">Yes</span>
          </div> -->
          <div v-for="(item, index) in sessions" :key="index">
            <div
              class="d-flex align-center mt-2"
              v-if="!item.ball_running && !item.ballrunning && !item.suspended"
            >
              <!-- <div v-if="item.isMarketSuspended">
                <oddsMessage-box message="Market Suspended" />
              </div>
              <div v-else-if="item.ballChalu">
                <oddsMessage-box message="Bowl Running" />
              </div>
              <div v-else-if="item.isSuspended">
                <oddsMessage-box message="Suspended" />
              </div> -->
              <div class="d-flex">
                <odds-box
                  :odds.sync="item.no"
                  :volume.sync="item.no_rate"
                  :color="'rgb(249, 148, 186)'"
                  :fancy="true"
                />
                <odds-box
                  :odds.sync="item.yes"
                  :volume.sync="item.yes_rate"
                  :color="'rgb(114, 187, 239)'"
                  :fancy="true"
                />
              </div>
              <span class="black-transparent-patch">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="display" class="new">{{ name }}</div>
      <div v-if="displayWebsite" class="new">
        {{ website }}
      </div>
      <nano-player
        v-if="loaded && liveStreamDetails.playerType == 'nanocosmos'"
        :streams="liveStreamDetails.streams"
        :message="liveStreamDetails.message"
      />
      <antmedia-player
        v-if="loaded && liveStreamDetails.playerType == 'antmedia'"
        :streams="liveStreamDetails"
        :message="liveStreamDetails"
      />
      <new-player
        v-if="loaded && liveStreamDetails.playerType == 'new'"
        :streams="liveStreamDetails"
      />
      <livetv-player
        v-if="loaded && liveStreamDetails.playerType == 'livetv'"
        :streams="liveStreamDetails"
      />
      <div class="mx-3 my-3" style="border-top: 1px solid gold">
        <div class="mt-3 d-flex flex-column" style="width: 100%">
          <div class="d-flex justify-space-between">
            <div class="font-18" style="width: 100%">
              {{ liveStreamDetails.name }}
            </div>
            <div class="pl">
              <v-btn @click="editFavorite()" icon>
                <v-icon class="icon-text" height="15px">{{
                  !liveStreamDetails.favorite ? "mdi-star-outline" : "mdi-star"
                }}</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <div class="font-14">{{ liveStreamDetails.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import circularLoader from "../components/circularLoader.vue";
import NanoPlayer from "../components/NanoPlayer.vue";
import AntmediaPlayer from "../components/AntmediaPlayer.vue";
import gameService from "@/services/gameService";
import favoriteService from "@/services/favoriteService";
import * as SocketioService from "@/services/socketioService.js";
import oddsBox from "@/components/oddsBox.vue";
import oddsMessageBox from "@/components/oddsMessageBox.vue";
import NewPlayer from "../components/NewPlayer.vue";
import LivetvPlayer from "../components/LiveTvPlayer.vue";
export default {
  components: {
    NanoPlayer,
    circularLoader,
    AntmediaPlayer,
    oddsBox,
    oddsMessageBox,
    NewPlayer,
    LivetvPlayer,
  },
  data() {
    return {
      modal_1: false,
      liveStreamDetails: [],
      zIndex: 100,
      loaded: false,
      loading: false,
      display: false,
      usernameTurn: true,
      displayWebsite: false,
      websiteTurn: false,
      name: this.$store.state.user.username,
      website: window.location.hostname,
      isMobile: false,
      isIos: false,
      isFullscreen: true,
      timer: null,
      interval1: null,
      interval2: null,
      eventId: undefined,
      // marketId: undefined,
      // sessionId: undefined,
      // bookMakerId: undefined,
      oddsData: [],
      bookMakerData: [],
      // bookMakerSuspened: false,
      // bookMakerBallRunning: false,
      sessions: [],
      // bookMarketStatus: "",
      initialOddsLoaded: false,
      initialSessionsLoaded: false,
      initialBookMakerLoaded: false,
      marketsVisible: true,
      showTooltip: true,
      tooltipTimer: undefined,
    };
  },
  async created() {
    this.id = this.$route.params.id;
    this.checkIos();
    this.checkMobile();
    await this.getLiveStreamDetails();
    await this.OddsManage();
    await this.oddsUpdateService();
    await this.gameUpdateService();
    this.hideTooltip();
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    checkMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      }
    },
    async joinSockets() {
      await SocketioService.socket.joinRooms({
        roomNames: [
          "1sportsstream" + this.$store.state.user.userId,
          "streamRoom",
        ],
      });
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    },
    async joinOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.joinRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
    async socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinSockets();
        this.joinOddsSockets();
        await SocketioService.socket.joinRoom({ roomName: "streamRoom" });
      });
      // SocketioService.eventBus.$on("reconnectedPlaySocket", async () => {
      //   console.log("reconnected");
      //   this.joinPlaySockets();
      // });
    },
    async getLiveStreamDetails() {
      this.loaded = false;
      this.loading = true;
      try {
        const result = await gameService.getLiveStreamDetails(this.id);
        if (result.status == 200) {
          const decrypted = this.$CryptoJS.AES.decrypt(
            result.data,
            process.env.VUE_APP_ENV_CRYPTO_KEY
          ).toString(this.$CryptoJS.enc.Utf8);
          this.liveStreamDetails = JSON.parse(decrypted);
          if (this.liveStreamDetails.eventId)
            this.eventId = this.liveStreamDetails.eventId;
          // if (this.liveStreamDetails.marketId)
          //   this.marketId = this.liveStreamDetails.marketId;
          // if (this.liveStreamDetails.sessionId)
          //   this.sessionId = this.liveStreamDetails.sessionId;
          this.joinSockets();
          this.showUserNameService();
          this.showWebsiteService();
          this.socketReconnection();
          this.displayUsernameAndWebsite();
          this.fullScreenManagement();
          this.loaded = true;
        }
      } catch (error) {
        console.log(error);
        if (
          document.fullscreenElement /* Standard syntax */ ||
          document.webkitFullscreenElement /* Safari and Opera syntax */ ||
          document.msFullscreenElement /* IE11 syntax */
        ) {
          this.exitFullscreen1();
        }
        if (error && error.response) {
          if (
            error.response.status == 404 &&
            error.response.data == "Not Subscribed"
          ) {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Please Recharge to Watch Match"),
              color: "error ",
              isVisible: true,
            });
          } else if (
            error.response.status == 404 &&
            error.response.data == "User Blocked"
          ) {
            this.$store.commit("snackbar/setSnackbar", {
              content: "Your account is blocked, kindly contact admin",
              color: "error ",
              isVisible: true,
            });
          } else {
            this.$store.commit("snackbar/setSnackbar", {
              content: this.$t("Something Went Wrong"),
              color: "error ",
              isVisible: true,
            });
          }
        } else {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Something Went Wrong"),
            color: "error ",
            isVisible: true,
          });
        }
        this.$router.push("/");
        console.log(error);
      }
      this.loading = false;
    },
    async oddsUpdateService() {
      SocketioService.eventBus.$on("oddsData", async (data) => {
        if (
          data.event == this.eventId &&
          data.args &&
          data.args.length > 0 &&
          data.args[0] &&
          data.args[0].odds &&
          data.args[0].odds.length > 0
        ) {
          this.assignOdds(data.args[0].odds);
        }
        if (
          data.event == this.eventId + "-FM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          if (data.args[0].fancyData && data.args[0].fancyData.length > 0) {
            this.sessions = data.args[0].fancyData;
            this.initialSessionsLoaded = true;
          } else {
            this.initialSessionsLoaded = false;
          }
        }
        if (
          data.event == this.eventId + "-BM" &&
          data.args &&
          data.args.length > 0 &&
          data.args[0]
        ) {
          this.assignBookMaker(data.args[0].runners);
        }
      });
    },
    async gameUpdateService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          data.eventId != this.eventId &&
          (data.id == this.$route.params.id || data.id == this.id)
        ) {
          if (this.eventId)
            await SocketioService.socket.leaveRooms({
              roomNames: [
                this.eventId,
                this.eventId + "-BM",
                this.eventId + "-FM",
              ],
            });
          this.eventId = data.eventId;
          this.OddsManage();
        }
      });
    },
    async OddsManage() {
      if (this.eventId) {
        await this.joinOddsSockets();
      }
    },
    // async getOddsFromApi() {
    //   try {
    //     const obj = {
    //       accessLevel: "Player",
    //       game: "Cricket",
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getOddsPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignOdds(result.data.data[0].runners);
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getSessionsFromApi() {
    //   try {
    //     const obj = {
    //       market: this.sessionId,
    //     };
    //     const result = await playOddsService.getSessionsPerGameFromPlay(obj);
    //     if (result.status == 200 ** result.data && result.data.length > 0) {
    //       this.initialSessionsLoaded = true;
    //       this.sessions = result.data;
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    // async getBookMakerFromApi() {
    //   try {
    //     const obj = {
    //       parentId: this.sessionId,
    //     };
    //     const result = await playOddsService.getBookMakerPerGameFromPlay(obj);
    //     if (result.status == 200) {
    //       if (
    //         result.data &&
    //         result.data.data &&
    //         result.data.data[0] &&
    //         result.data.data[0].runners &&
    //         result.data.value
    //       ) {
    //         this.assignBookMaker(
    //           result.data.data[0].runners,
    //           result.data.data[0].marketStatus
    //         );
    //         this.bookMakerId = result.data.data[0].betfairId;
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return error;
    //   }
    // },
    assignOdds(data) {
      this.oddsData = data;
      this.initialOddsLoaded = true;
    },
    assignBookMaker(data) {
      this.bookMakerData = data;
      // if (suspended == "1") this.bookMakerSuspened = suspended;
      // if (ballrunning == "1") this.bookMakerBallRunning = ballrunning;
      this.initialBookMakerLoaded = true;
    },
    hideTooltip() {
      if (this.tooltipTimer) clearTimeout(this.tooltipTimer);
      this.tooltipTimer = setTimeout(
        function () {
          this.showTooltip = false;
        }.bind(this),
        1500
      );
    },
    showUserNameService() {
      SocketioService.eventBus.$on("show-username", async () => {
        this.display = true;
        setTimeout(
          function () {
            this.display = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    showWebsiteService() {
      SocketioService.eventBus.$on("show-website", async () => {
        this.displayWebsite = true;
        setTimeout(
          function () {
            this.displayWebsite = false;
          }.bind(this),
          process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
        );
      });
    },
    displayUsernameAndWebsite() {
      this.interval1 = setInterval(() => {
        if (this.usernameTurn) {
          this.display = true;
          setTimeout(
            function () {
              this.display = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.usernameTurn = !this.usernameTurn;
      }, 600000);
      this.interval2 = setInterval(() => {
        if (this.websiteTurn) {
          this.displayWebsite = true;
          setTimeout(
            function () {
              this.displayWebsite = false;
            }.bind(this),
            process.env.VUE_APP_ENV_SHOWUSERNAME_TIMING
          );
        }
        this.websiteTurn = !this.websiteTurn;
      }, 600000);
    },
    async fullScreenManagement() {
      window.addEventListener("fullscreenchange", (event) => {
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
        }
      });
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        this.isFullscreen = true;
      } else {
        this.isFullscreen = false;
        if (this.isMobile && !this.isIos) {
          await this.$dialog
            .confirm({
              text: this.$t("Enter Full-screen?"),
              actions: {
                false: this.$t("popups.no"),
                true: this.$t("popups.yes"),
              },
            })
            .then((res) => {
              if (res == true) {
                this.requestFullscreen1();
              }
            });
        }
      }
    },
    requestFullscreen1() {
      var elem = document.getElementById("app");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitEnterFullscreen) {
        elem.webkitEnterFullscreen();
      }
      screen.orientation.lock("landscape-primary");
    },
    exitFullscreen1() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozRequestFullScreen) {
        document.mozExitFullScreen();
      }
      screen.orientation.unlock();
    },
    func() {
      this.modal_1 = !this.modal_1;
    },
    async back() {
      await this.$dialog
        .confirm({
          text: this.$t("Do you really want to exit?"),
          actions: {
            false: this.$t("popups.no"),
            true: this.$t("popups.yes"),
          },
        })
        .then((res) => {
          if (res == true) {
            this.$router.push("/");
          }
        });
    },
    toggle() {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
      ) {
        this.exitFullscreen1();
      } else {
        this.requestFullscreen1();
      }
    },
    async editFavorite() {
      const data = {
        gameId: this.liveStreamDetails._id,
        userId: this.$store.state.user.userId,
        status: !this.liveStreamDetails.favorite ? "enabled" : "disabled",
      };
      try {
        const result = await favoriteService.editFavorite(data);
        if (result.status == 200) {
          if (result.data == "You can't add more than 4 favorites") {
            this.$store.commit("snackbar/setSnackbar", {
              content: result.data,
              color: "error",
              isVisible: true,
            });
          } else {
            this.getLiveStreamDetails();
            this.$store.commit("snackbar/setSnackbar", {
              content:
                result.data.status == "enabled"
                  ? this.$t("Added to Favorite")
                  : this.$t("Removed from Favorite"),
              color: "success",
              isVisible: true,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async leaveOddsSockets() {
      if (this.eventId)
        await SocketioService.socket.leaveRooms({
          roomNames: [this.eventId, this.eventId + "-BM", this.eventId + "-FM"],
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isIos) {
      next();
      window.location = window.location;
    } else {
      next();
    }
  },
  async beforeDestroy() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    if (this.tooltipTimer) {
      clearTimeout(this.tooltipTimer);
    }
    await this.leaveOddsSockets();
  },
};
</script>
<style lang="scss" scoped>
.icon-text {
  color: #b6842d !important;
}
.oddsText {
  // background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  position: absolute;
  top: 10px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.75rem;
  right: 0;
  // transform: translate(0, -50%);
  z-index: 900;
}
.sessionsText {
  // background-color: rgba(0, 0, 0, 0.5);
  color: white !important;
  position: absolute;
  top: 10px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 0.75rem;
  left: 0;
  // transform: translate(0, -50%);
  z-index: 140;
}
.oddsHead {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  font-size: 0.75rem;
  font-weight: bold;
}
.oddSubs {
  // min-width: 60px;
  text-align: center;
  margin-left: 5px;
}
.alignCenter {
  text-align: center;
  width: 120px;
}
@media only screen and (min-width: 1000px) {
  // .oddSubs {
  //   min-width: 80px;
  // }
  .alignCenter {
    width: 140px;
  }
}
@media only screen and (max-width: 700px) {
  .oddsText {
    font-size: 0.55rem;
  }
  .sessionsText {
    font-size: 0.55rem;
  }
  .oddsHead {
    font-size: 0.55rem;
  }
  // .oddSubs {
  //   min-width: 30px;
  // }
  .alignCenter {
    width: 80px;
  }
}
</style>

<style>
.toolBar {
  position: absolute;
  bottom: 40px;
  left: 10px;
  z-index: 150;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
.black-transparent-patch {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
