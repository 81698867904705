<template>
  <div class="fscreenhide">
    <div v-if="!playstarted && !favorites" class="d-flex justify-center">
      <flashLoader />
    </div>
    <div
      :id="'remoteVideo' + index"
      style="width: 100%; position: relative"
      :class="{ httt: !favorites }"
    >
      <video
        :id="'videoControls' + index"
        width="100%"
        height="100%"
        style="position: relative; background: black !important"
        :class="{
          favorite1: favorites && count == 1,
          favorite2: favorites && count == 2,
          favorite3: favorites && count == 3,
          favorite4: favorites && count == 4,
        }"
        autoplay
        :controls="playstarted"
        :muted="favorites ? true : false"
        playsinline
        disablePictureInPicture
      ></video>
      <button
        class="iphoneToolBar"
        @click="controlAudioForIphone()"
        v-if="isIos"
      >
        <v-icon v-if="muted">mdi-volume-mute</v-icon>
        <v-icon v-if="!muted">mdi-volume-high</v-icon>
      </button>
      <div v-if="displaycommentaryText" class="commentaryText">
        {{ commentaryText }}
      </div>
      <marquee v-if="messageText" class="messageText">
        {{ messageText }}
      </marquee>
    </div>
  </div>
</template>

<script>
import * as SocketioService from "@/services/socketioService.js";
import flashLoader from "../components/flashLoader.vue";
import * as Flashphoner from "@flashphoner/websdk";
import gameService from "@/services/gameService";
import router from "../router/index";
const SESSION_STATUS = Flashphoner.constants.SESSION_STATUS;
const STREAM_STATUS = Flashphoner.constants.STREAM_STATUS;
// const Browser = Flashphoner.Browser;
// const PRELOADER_URL = require("@/assets/media/preloader.mp4");
const ballStart = require("@/assets/sounds/ballStart.mp3");
const zero = require("@/assets/sounds/zero.mp3");
const one = require("@/assets/sounds/one.mp3");
const two = require("@/assets/sounds/two.mp3");
const three = require("@/assets/sounds/three.mp3");
const four = require("@/assets/sounds/four.mp3");
const six = require("@/assets/sounds/six.mp3");
const wicket = require("@/assets/sounds/wicket.mp3");
const noball = require("@/assets/sounds/noball.mp3");
const wide = require("@/assets/sounds/wide.mp3");
const freehit = require("@/assets/sounds/freehit.mp3");
const playersAreComing = require("@/assets/sounds/playersAreComing.mp3");
const heavyRain = require("@/assets/sounds/heavyRain.mp3");
const rain = require("@/assets/sounds/rain.mp3");
const coversAreComing = require("@/assets/sounds/coversAreComing.mp3");
const batsmanInjured = require("@/assets/sounds/batsmanInjured.mp3");
// const bowlerInjured = require("@/assets/sounds/bowlerInjured.mp3");
const appeal = require("@/assets/sounds/appeal.mp3");
const thirdUmpire = require("@/assets/sounds/thirdUmpire.mp3");
const notOut = require("@/assets/sounds/notout.mp3");
const inTheAir = require("@/assets/sounds/ballInTheAir.mp3");
const bowlerStopped = require("@/assets/sounds/bowlerStopped.mp3");
const checking = require("@/assets/sounds/checking.mp3");
const fastBowler = require("@/assets/sounds/fastBowler.mp3");
const spinBowler = require("@/assets/sounds/spinner.mp3");
const breakSound = require("@/assets/sounds/break.mp3");
const review = require("@/assets/sounds/review.mp3");
const overthrow = require("@/assets/sounds/overthrow.mp3");
// const catchDrop = require("@/assets/sounds/catchDrop.mp3");
const commenatry = require("@/assets/sounds/commentary.mp3");
export default {
  props: ["index", "streams", "favorites", "count", "id"],
  data() {
    return {
      isFullscreen: false,
      commentaryText: "",
      displaycommentaryText: false,
      audio: null,
      playstarted: false,
      messageText: this.streams.message,
      isIos: false,
      gameId: undefined,
      apiFailed: undefined,
      session: null,
      remoteVideo: null,
      playStreamObj: null,
      restartTimeout: 3000, //ms
      restartMaxTimes: 100, //will try to restart playback for 5 minutes
      restartCount: 0,
      restartTimedrId: null,
      muted: this.favorites ? true : false,
    };
  },
  components: { flashLoader },
  async mounted() {
    this.checkIos();
    this.startPlay();
    this.gameId = this.$route.params.id;
    this.manageFullScreen();
    await this.joinSockets();
    await this.commentaryService();
    await this.logOutGame();
    await this.gameLogsJoin();
    await this.updateGamesService();
    this.handlemultipleVolumeInFavorites();
    await this.socketReconnection();
    if (document.getElementById("remoteVideo" + this.index)) {
      document
        .querySelector("#remoteVideo" + this.index)
        .addEventListener("click", (e) => e.preventDefault());
    }
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    startPlay() {
      this.onLoad();
      this.connect();
    },
    manageFullScreen() {
      if (!this.favorites) {
        window.addEventListener("fullscreenchange", (event) => {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
          ) {
            this.isFullscreen = true;
          } else {
            this.isFullscreen = false;
          }
        });
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.msFullscreenElement
        ) {
          this.isFullscreen = true;
        } else {
          this.isFullscreen = false;
        }
      }
    },
    async joinSockets() {
      if (this.favorites) {
        await SocketioService.socket.joinRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.joinRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
    async logOutGame() {
      SocketioService.eventBus.$on("logOut", async () => {
        router.push("/");
      });
    },
    async gameLogsJoin() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.$route.params.id,
          event: "join",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async gameLogsLeft() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.gameId,
          event: "left",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async commentaryService() {
      SocketioService.eventBus.$on("commentary", async (data) => {
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.text && data.com !== "Clear") {
            this.displaycommentaryText = true;
            this.commentaryText = data.com;
          } else {
            this.commentaryText = "";
            this.displaycommentaryText = false;
          }
          if (
            data.sound &&
            !document.getElementById("remoteVideo" + this.index).muted
          ) {
            if (this.audio) {
              this.audio.pause();
            }
            if (data.com == "0 Runs") this.audio = new Audio(zero);
            else if (data.com == "1 Run") this.audio = new Audio(one);
            else if (data.com == "2 Runs") this.audio = new Audio(two);
            else if (data.com == "3 Runs") this.audio = new Audio(three);
            else if (data.com == "4 Runs") this.audio = new Audio(four);
            else if (data.com == "6 Runs") this.audio = new Audio(six);
            else if (data.com == "Wicket") this.audio = new Audio(wicket);
            else if (data.com == "No Ball") this.audio = new Audio(noball);
            else if (data.com == "Wide") this.audio = new Audio(wide);
            else if (data.com == "Free Hit") this.audio = new Audio(freehit);
            else if (data.com == "Ball Start")
              this.audio = new Audio(ballStart);
            else if (data.com == "Players Are Coming")
              this.audio = new Audio(playersAreComing);
            else if (data.com == "Heavy Rain")
              this.audio = new Audio(heavyRain);
            else if (data.com == "Rain") this.audio = new Audio(rain);
            else if (data.com == "Covers Are Coming")
              this.audio = new Audio(coversAreComing);
            else if (data.com == "Batsman Injured")
              this.audio = new Audio(batsmanInjured);
            // else if (data.com == "Bowler Injured")
            //   this.audio = new Audio(bowlerInjured);
            else if (data.com == "Appeal") this.audio = new Audio(appeal);
            else if (data.com == "Third Umpire")
              this.audio = new Audio(thirdUmpire);
            else if (data.com == "Not Out") this.audio = new Audio(notOut);
            else if (data.com == "In The Air") this.audio = new Audio(inTheAir);
            else if (data.com == "Bowler Stop")
              this.audio = new Audio(bowlerStopped);
            else if (data.com == "Checking") this.audio = new Audio(checking);
            else if (data.com == "Fast Bowler")
              this.audio = new Audio(fastBowler);
            else if (data.com == "Spin Bowler")
              this.audio = new Audio(spinBowler);
            else if (data.com == "Break") this.audio = new Audio(breakSound);
            else if (data.com == "Review") this.audio = new Audio(review);
            else if (data.com == "Overthrow") this.audio = new Audio(overthrow);
            // else if (data.com == "Catch Drop")
            //   this.audio = new Audio(catchDrop);
            else if (data.com == "Commentary")
              this.audio = new Audio(commenatry);
            else if (data.com == "Clear") this.audio = new Audio("");
            else {
              return;
            }
            if (
              data.com !== "Clear" &&
              data.com !== "Break" &&
              data.com !== "Good Night" &&
              data.com !== "Match Delayed" &&
              data.com !== "Wet Outfield" &&
              data.com !== "Review"
            )
              this.audio.play();
          }
        }
      });
    },
    async updateGamesService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.message) {
            this.messageText = data.message;
          }
          if (data.message == "") {
            this.messageText = "";
          }
        }
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinSockets();
      });
    },
    async leaveSockets() {
      if (this.favorites) {
        await SocketioService.socket.leaveRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.leaveRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
    handlemultipleVolumeInFavorites() {
      if (this.favorites) {
        const vid = document.getElementById("videoControls" + this.index);
        vid.onvolumechange = () => {
          if (vid.muted == false) {
            if (this.isIos) {
              this.muted = false;
            }
            for (let i = 0; i < this.count; i++) {
              try {
                if (
                  i !== this.index &&
                  document.getElementById("playerDiv" + i) &&
                  !document.getElementById("playerDiv" + i).muted
                ) {
                  document.getElementById("playerDiv" + i).muted = true;
                }
                if (
                  i !== this.index &&
                  document.getElementById("videoControls" + i) &&
                  !document.getElementById("videoControls" + i).muted
                ) {
                  document.getElementById("videoControls" + i).muted = true;
                }
                SocketioService.eventBus.$emit("muteLiveTvAudio", this.index);
              } catch (error) {
                console.log(error);
              }
              try {
                if (
                  i !== this.index &&
                  document.getElementById("h5live-playerDiv" + i) &&
                  !document.getElementById("h5live-playerDiv" + i).muted
                ) {
                  document.getElementById("h5live-playerDiv" + i).muted = true;
                }
              } catch (error) {
                console.log(error);
              }
            }
          } else {
            if (this.isIos) {
              this.muted = true;
            }
          }
        };
      } else {
        if (this.isIos) {
          const vid = document.getElementById("videoControls" + this.index);
          vid.onvolumechange = () => {
            if (vid.muted == false) {
              this.muted = false;
            } else {
              this.muted = true;
            }
          };
        }
      }
    },
    onLoad() {
      try {
        Flashphoner.init({});
        this.remoteVideo = document.getElementById("remoteVideo" + this.index);
      } catch (e) {
        console.log("Flashphoner init error", e);
        this.apiFailed = "Your browser does not support this technology";
      }
    },
    connect() {
      if (!this.session) {
        Flashphoner.createSession({
          urlServer: process.env.VUE_APP_ENV_NEW_PLAYER,
          timeout: 500000,
        })
          .on(SESSION_STATUS.ESTABLISHED, (session) => {
            this.session = session;
            console.log("session established");
            this.onConnected();
          })
          .on(SESSION_STATUS.DISCONNECTED, () => {
            console.log("session disconnected");
            this.onDisconnected();
          })
          .on(SESSION_STATUS.FAILED, () => {
            console.log("session failed");
            this.onDisconnected();
            this.tryToRestart();
          });
      } else {
        console.log("session disconnect");
        session.disconnect();
      }
    },
    initPlay() {
      if (!this.remoteVideo) return;
      if (!this.playStreamObj) {
        // console.log(Flashphoner.getsoundsProviders(), Browser.isSafariWebRTC());
        // if (Flashphoner.getMediaProviders()[0] === "WSPlayer") {
        //   console.log("playFirstSound");
        //   Flashphoner.playFirstSound();
        // } else if (
        //   Browser.isSafariWebRTC() ||
        //   Flashphoner.getMediaProviders()[0] === "MSE"
        // ) {
        //   console.log("playFirstVideo")
        //     Flashphoner.playFirstVideo(this.remoteVideo, false, PRELOADER_URL)
        //       .then(() => {
        //         this.playStream();
        //       })
        //       .catch(function (error) {
        //         // Low Power Mode detected, user action is needed to start playback in this mode
        //         console.log(
        //           "Can't atomatically play participant" +
        //             participant.name() +
        //             " stream, use Play button"
        //         );
        //       });
        //   return;
        // }
        this.playStream();
      } else {
        this.playStreamObj.stop();
      }
    },
    playStream() {
      if (this.session && this.remoteVideo) {
        const videoControls = document.getElementById(
          "videoControls" + this.index
        );
        this.session
          .createStream({
            name: String(this.streams.streamId),
            display: this.remoteVideo,
            remoteVideo: videoControls,
          })
          .on(STREAM_STATUS.PLAYING, (stream) => {
            console.log("Playing");
            this.onPlaying(stream);
          })
          .on(STREAM_STATUS.STOPPED, () => {
            console.log("Play Stopped");
            this.clearRestart();
            this.onStopped();
          })
          .on(STREAM_STATUS.FAILED, () => {
            console.log("Failed to play");
            this.tryToRestart();
            this.onStopped();
          })
          .play();
      }
    },
    onConnected() {
      this.initPlay();
      this.onStopped();
    },
    onDisconnected() {
      this.session = null;
      console.log("session disconnected");
      this.onStopped();
    },
    onPlaying(stream) {
      this.playstarted = true;
      this.playStreamObj = stream;
    },
    onStopped() {
      this.playStreamObj = null;
    },
    tryToRestart() {
      this.restartTimerId = setTimeout(() => {
        if (
          this.session &&
          this.session.status() != STREAM_STATUS.PLAYING &&
          this.restartCount < this.restartMaxTimes
        ) {
          this.initPlay();
          this.restartCount++;
        }
        if (this.restartCount >= this.restartMaxTimes) {
          console.log(
            "Tried to restart playback for " +
              this.restartMaxTimes +
              " times with " +
              this.restartTimeout +
              " ms interval, cancelled"
          );
        }
      }, this.restartTimeout);
    },
    clearRestart() {
      clearTimeout(this.restartTimerId);
      this.restartCount = 0;
    },
    controlAudioForIphone() {
      document.getElementById("videoControls" + this.index).muted =
        !document.getElementById("videoControls" + this.index).muted;
      this.muted = !this.muted;
    },
  },
  async beforeDestroy() {
    await this.gameLogsLeft();
    this.onDisconnected();
    if (this.restartTimerId) clearTimeout(this.restartTimerId);
    await this.leaveSockets();
  },
};
</script>

<style lang="scss" scoped>
.fscreenhide video::-webkit-media-controls-fullscreen-button {
  display: none;
}
video::-webkit-media-controls-timeline {
  display: none !important;
}
video {
  object-fit: contain;
}
video::-webkit-media-controls-current-time-display {
  display: none;
}
.httt {
  height: 100vh;
}
.iphoneToolBar {
  position: absolute;
  bottom: 50px;
  right: 10px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
</style>
