<template>
  <div class="text-center bg">
    <v-card class="card-section">
      <div class="mt-6">
        <img src="@/assets/images/logo.png" alt="logo" height="80px" />
      </div>
      <form @submit.prevent="login">
        <div class="input-field mx-6 my-6">
          <div class="mt-3 d-flex">
            <v-text-field
              v-model="userName"
              dense
              label="Username"
              :error-messages="userNameErrors"
              required
              @input="$v.userName.$touch()"
              @blur="$v.userName.$touch()"
            >
            </v-text-field>
          </div>
          <div class="mt-3 password">
            <v-text-field
              v-model="password"
              dense
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              @click:append="show1 = !show1"
              :label="$t('placeholder.password')"
              :error-messages="passwordErrors"
              required
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
            >
            </v-text-field>
          </div>
          <div class="pt-7 text-center">
            <v-btn type="submit" class="font-14 gold-btn py-5">{{
              $t("login.login")
            }}</v-btn>
          </div>
        </div>
      </form>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="450">
      <v-card style="border: 1px solid white !important">
        <v-card-title class="text-h5 gold-bg black-txt">
          {{ $t("popups.loginOnAnotherDeviceDetected") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#b6842d" @click="dialog = false" outlined light>
            {{ $t("popups.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import userService from "@/services/userService";
import * as SocketioService from "@/services/socketioService.js";
import VueJwtDecode from "vue-jwt-decode";
export default {
  name: "Login",
  props: ["screenExceed"],
  data() {
    return {
      userName: "",
      password: "",
      show1: false,
      dialog: false,
      isIos: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    userName: { required, minLength: minLength(3) },
    password: {
      required,
      minLength: minLength(4),
    },
  },
  computed: {
    userNameErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.minLength &&
        errors.push("Username must be at least 3 characters long");
      !this.$v.userName.required && errors.push("Username is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 4 characters long");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
  },
  async created() {
    if (this.$store.state.user.userId) {
      let roomNames = ["all"];
      roomNames.push("1sports" + this.$store.state.user.userId);
      roomNames.push("1sportsstream" + this.$store.state.user.userId);
      roomNames.push("streamRoom");
      await SocketioService.socket.leaveRooms(roomNames);
    }
    localStorage.removeItem("accessToken");
    this.$store.commit("user/setUser", false);
    this.$store.commit("user/setUserId", null);
    this.$store.commit("user/setUserLanguage", "en");
    this.checkIos();
    if (this.screenExceed) {
      this.dialog = true;
    }
  },
  methods: {
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    async login() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        const data = {
          username: this.userName,
          password: this.password,
          iPhone: this.isIos,
        };
        const result = await userService.login(data);
        if (result.status == 200) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Login Successfull"),
            color: "success",
            isVisible: true,
          });
          localStorage.accessToken = result.data;
          const decoded = VueJwtDecode.decode(localStorage.accessToken);
          if (decoded.deviceType == "Android-Web" && this.isIos) {
            this.$store.commit("snackbar/setSnackbar", {
              content:
                "Please login from android/web or contact admin to get Iphone account",
              color: "error",
              isVisible: true,
            });
            return;
          }
          this.$store.commit("user/setUser", true);
          this.$store.commit("user/setUserId", decoded.userId);
          this.$store.commit("user/setUserName", decoded.username);
          if (decoded && decoded.planDetails)
            this.$store.commit("user/setPlanDetails", decoded.planDetails);
          if (decoded.language) {
            this.$store.commit("language/setLanguage", decoded.language);
            this.$i18n.locale = this.$store.state.language.language;
          }
          await SocketioService.socket.joinRooms({
            roomNames: [
              "1sports" + decoded.userId,
              "1sportsstream" + this.$store.state.user.userId,
            ],
          });
          await SocketioService.socket.streamRoom({
            roomName: "1sportsstream" + this.$store.state.user.userId,
            accessToken: localStorage.accessToken,
          });
          this.$router.push("/");
        }
      } catch (e) {
        console.log(e);
        this.$store.commit("snackbar/setSnackbar", {
          content:  "Invalid username or password",
          color: "error ",
          isVisible: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.card-section {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #20221f !important;
  border: 5px solid #b6842d !important;
  opacity: 0.9;
  width: 85vw;
  max-width: 600px !important;
}
.input-field {
  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white !important;
  }
  .theme--light.v-label {
    color: white !important;
  }
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: white !important;
  }
  .v-application .primary--text {
    color: white !important;
    caret-color: white !important;
  }
  .v-label {
    font-size: 14px !important;
  }
}
.bg {
  width: 100%;
  height: 100%;
  background: url("https://wallpaperaccess.com/full/3818759.jpg") no-repeat
    center center;
  background-size: cover;
}
.password .mdi:before {
  color: white;
}
</style>
