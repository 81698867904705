var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center"},[_c('circularLoader')],1):_vm._e(),_c('div',[(_vm.favorites.length == 0 && !_vm.loading)?_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"my-5",attrs:{"src":require("@/assets/images/pana.svg"),"aspect-ratio":"1.5"}}),_c('p',{staticClass:"font-16"},[_vm._v(_vm._s(_vm.$t("You have not added any favorite games")))])]):_c('v-row',{staticStyle:{"background":"black !important"},attrs:{"no-gutters":""}},_vm._l((_vm.favorites),function(favorite,index){return _c('v-col',{key:favorite._id,attrs:{"cols":_vm.favorites.length > 3 ? 6 : 12,"sm":_vm.favorites.length > 1 ? 6 : 12}},[_c('Video',{key:index,attrs:{"favorite":favorite,"index":index,"count":_vm.favorites.length},on:{"reRender":_vm.getFavorites}}),(_vm.display)?_c('div',{staticClass:"new"},[_vm._v(_vm._s(_vm.name))]):_vm._e(),(_vm.displayWebsite)?_c('div',{staticClass:"new"},[_vm._v(" "+_vm._s(_vm.website)+" ")]):_vm._e()],1)}),1),_c('div',{staticClass:"toolBar d-flex flex-column"},[_c('v-icon',{staticClass:"mt-1 mb-3",attrs:{"center":"","color":"#b6842d"},on:{"click":_vm.back}},[_vm._v("mdi-backburger")]),(!_vm.isFullscreen && !_vm.isIos && !_vm.loading && _vm.favorites.length != 0)?_c('v-icon',{attrs:{"center":"","color":"white"},on:{"click":function($event){return _vm.toggle()}}},[_vm._v(" mdi-fullscreen ")]):_vm._e(),(
          _vm.isFullscreen &&
          !_vm.isMobile &&
          !_vm.isIos &&
          !_vm.loading &&
          _vm.favorites.length != 0
        )?_c('v-icon',{attrs:{"center":"","color":"white"},on:{"click":function($event){return _vm.toggle()}}},[_vm._v(" mdi-fullscreen-exit ")]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }