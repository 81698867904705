<template>
  <div style="position: relative; height: 100%">
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 12 : 2"
        :class="{ 'on-hover': hover }"
        class="my-1 cardbg"
        tile
        @click="!upcoming ? playStream() : null"
      >
        <div
          class="d-flex flex-column justify-space-between"
          style="height: 100%; background: rgb(0 0 0 / 60%)"
        >
          <div style="height: 100%" class="d-flex flex-column justify-center">
            <div class="d-flex align-center py-2">
              <img
                src="@/assets/images/video-icon.png"
                class="img-fluid ml-3"
                style="max-height: 50px"
              />
              <!-- <v-icon x-large color="#b6842d"> mdi-play-circle </v-icon> -->
              <div
                class="d-flex justify-space-between align-center ml-3"
                style="width: 100%"
              >
                <div
                  class="d-flex flex-column justify-space-between"
                  style="width: 100%"
                >
                  <div class="font-16 font-size-title" style="width: 100%">
                    <strong>{{ gamesData.name }}</strong>
                  </div>
                  <div>
                    <div class="font-12 font-size-desc">
                      <strong>{{ gamesData.description }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!upcoming && !hover" class="text-center gold-bg font-14">
            <strong style="color: #533201">{{ gamesData.type }}</strong>
          </div>
          <div
            v-if="!upcoming && hover"
            class="text-center white-bg font-14 black-txt"
          >
            <strong>Click To Watch Live Now</strong>
          </div>
          <div
            class="text-center gold-bg rounded-lg rounded-t-0 font-16"
            v-if="upcoming"
          >
            <span> <v-icon color="#533201"> mdi-clock </v-icon></span>
            <span style="color: #533201">{{
              gamesData.startTime | formatDateTime
            }}</span>
          </div>
        </div>
      </v-card>
    </v-hover>
    <v-btn
      class="favorite"
      fab
      x-small
      light
      :color="!gamesData.favorite ? 'transparent' : 'white'"
      @click="editFavorite()"
      v-if="!upcoming"
    >
      <v-icon color="#b6842d">{{
        !gamesData.favorite ? "mdi-star-outline" : "mdi-star"
      }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import favoriteService from "@/services/favoriteService";
import NoSleep from "nosleep.js";
import gameService from "@/services/gameService";
export default {
  data() {
    return {
      zIndex: 100,
      nosleep: null,
    };
  },
  props: ["gamesData", "upcoming"],
  methods: {
    async editFavorite() {
      const data = {
        gameId: this.gamesData._id,
        userId: this.$store.state.user.userId,
        status: !this.gamesData.favorite ? "enabled" : "disabled",
      };
      try {
        const result = await favoriteService.editFavorite(data);
        if (result.status == 200) {
          if (result.data == this.$t("You can't add more than 4 favorites")) {
            this.$store.commit("snackbar/setSnackbar", {
              content: result.data,
              color: "error",
              isVisible: true,
            });
          } else {
            this.$emit("onEdit");
            this.$store.commit("snackbar/setSnackbar", {
              content:
                result.data.status == "enabled"
                  ? this.$t("Added to Favorite")
                  : this.$t("Removed from Favorite"),
              color: "success",
              isVisible: true,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async playStream() {
      if (this.$nosleep) this.$nosleep.disable();
      this.$nosleep = new NoSleep();
      this.$nosleep.enable();
      try {
        const result = await gameService.authenticateSubscribedUser();
        if (result.status == 200 && result.data == true) {
          this.$router.push({ path: `/livestream/${this.gamesData._id}` });
        }
      } catch (error) {
        console.log(error);
        if (
          error.response.status == 404 &&
          error.response.data == "Not Subscribed"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Account Expired"),
            color: "error ",
            isVisible: true,
          });
        } else if (
          error.response.status == 404 &&
          error.response.data == "User Blocked"
        ) {
          this.$store.commit("snackbar/setSnackbar", {
            content: "Your account is blocked kindly contact admin",
            color: "error ",
            isVisible: true,
          });
        } else {
          this.$store.commit("snackbar/setSnackbar", {
            content: this.$t("Something Went Wrong"),
            color: "error ",
            isVisible: true,
          });
        }
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.2s ease-in-out;
  border: 1px solid white !important;
}

.v-card:not(.on-hover) {
  opacity: 0.9;
  border: 1px solid #b6842d !important;
}
.cardbg {
  height: 100%;
  background-size: 100% 100%;
  margin-left: 3px;
  margin-right: 3px;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("https://s3.ap-south-1.amazonaws.com/sportstv365-files/e9ca99f3-5a8f-4dd7-b8d0-1fefee25d325.jpeg");
}
.font-size-desc {
  color: #b6842d;
}
.font-size-title {
  color: white;
}
.favorite {
  position: absolute;
  top: 7px;
  right: 10px;
  border: 1px solid #b6842d !important;
}
@media only screen and (max-width: 500px) {
  .font-size-title {
    font-size: 0.9375rem !important;
  }
  .font-size-desc {
    font-size: 0.75rem !important;
  }
}
@media only screen and (max-width: 400px) {
  .font-size-title {
    font-size: 0.92rem !important;
  }
  .font-size-desc {
    font-size: 0.7rem !important;
  }
}
</style>
<style>
.bg2 {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  opacity: 1;
  transition: 0.3s;
}
.c-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 70%;
  max-width: 300px;
  height: 246px;
  padding: 12px;
  background-color: white;
  border: 5px solid gold;
  border-radius: 5px;
  z-index: 10001;
  opacity: 1;
  transition: 0.6s;
  color: black;
}
.ptop {
  padding-top: 50px;
}
@media only screen and (max-width: 320px) {
  .c-modal {
    width: 90%;
  }
  .logo {
    width: 93.27px;
  }
}
</style>
