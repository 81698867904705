<template>
  <div
    class="oddsBox d-flex flex-column justify-center"
    :style="{ background: color }"
    :class="{
      greybg: !odds && !volume,
      oddsBoxF: favorites,
      oddsBoxFancy: fancy,
    }"
  >
    <div v-if="odds" class="odds" :class="{ oddsF: favorites }">
      {{ odds }}
    </div>
    <div v-if="volume" class="volume" :class="{ volumeF: favorites }">
      {{ volume }}
    </div>
    <div v-if="!odds && !volume">-</div>
  </div>
</template>

<script>
export default {
  props: ["odds", "volume", "color", "favorites", "fancy"],
};
</script>

<style lang="scss" scoped>
.oddsBox {
  min-width: 55px;
  min-height: 30px;
  color: black;
  text-align: center;
  border-radius: 0.3rem;
  margin-left: 5px;
}
.oddsBoxFancy {
  min-width: 35px;
  min-height: 20px;
  color: black;
  text-align: center;
  border-radius: 0.3rem;
  margin-left: 2px;
}
.odds {
  font-size: 14px !important;
  font-weight: 800 !important;
}
.volume {
  font-size: 12px !important;
  line-height: 100%;
  font-weight: 400 !important;
}
.oddsBoxF {
  min-width: 35px;
  min-height: 20px;
  color: black;
  text-align: center;
  border-radius: 0.3rem;
  margin-left: 2px;
}

.oddsF {
  font-size: 10px !important;
  font-weight: 800 !important;
}
.volumeF {
  font-size: 8px !important;
  line-height: 100%;
  font-weight: 400 !important;
}
.greybg {
  background: #e1e1e1 !important;
}
@media only screen and (min-width: 1000px) {
  .oddsBox {
    min-width: 65px;
    min-height: 45px;
  }
  .oddsBoxFancy {
    min-width: 45px;
    min-height: 35px;
  }
  .odds {
    font-size: 18px !important;
  }
  .volume {
    font-size: 16px !important;
  }
  .oddsBoxF {
    min-width: 45px;
    min-height: 35px;
  }
  .oddsF {
    font-size: 12px !important;
  }
  .volumeF {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 700px) {
  .oddsBoxFancy {
    min-width: 25px;
    min-height: 20px;
  }
  .oddsBox {
    min-width: 40px;
    min-height: 25px;
  }
  .odds {
    font-size: 12px !important;
  }
  .volume {
    font-size: 10px !important;
  }
  .oddsBoxF {
    min-width: 25px;
    min-height: 20px;
  }
  .oddsF {
    font-size: 7px !important;
  }
  .volumeF {
    font-size: 5px !important;
  }
}
</style>
