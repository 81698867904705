<template>
  <v-app>
    <v-main v-if="loading">
      <router-view />
      <Snackbar />
      <Footer
        v-if="
          $router.currentRoute.path !== '/login' &&
          !$router.currentRoute.path.includes('/livestream/') &&
          $router.currentRoute.path !== '/favorites' &&
          $router.currentRoute.path !== '/tv'
        "
      />
      <v-dialog
        v-if="dialog && $router.currentRoute.path == '/'"
        v-model="dialog"
        class="dialog"
        max-width="800px"
      >
        <div
          style="
            border: 2px solid #b6842d;
            background: black;
            position: relative;
          "
        >
          <div class="d-flex justify-end">
            <v-icon size="24px" @click="closeModal()" style="color: #b6842d">
              mdi-close
            </v-icon>
          </div>
          <div class="d-flex justify-center mt-3 mb-6">
            <v-btn @click="installer()" color="#b6842d" outlined
              ><v-icon color="#b6842d">mdi-download</v-icon
              ><span style="color: #b6842d"
                >&nbsp; Install 1 Sports App?</span
              ></v-btn
            >
          </div>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import Snackbar from "@/components/Snackbar.vue";
import Footer from "@/components/Footer.vue";
import * as SocketioService from "./services/socketioService.js";
// import * as playSocketService from "./services/playSocketService.js";
import NoSleep from "nosleep.js";

export default {
  name: "App",
  components: { Snackbar, Footer },
  data: () => ({
    registration: null,
    refreshing: false,
    loading: false,
    dialog: false,
  }),
  async created() {
    this.loading = false;
    const isBrowser = matchMedia("(display-mode: browser)").matches;
    if (!isBrowser) {
      window.resizeTo(912, 720);
    }
    SocketioService.socket.setupSocketConnection();
    // playSocketService.socket.setupSocketConnection();
    SocketioService.eventBus.$on("connection-left", (data) => {
      if (String(data) !== localStorage.accessToken) {
        this.$router.push({
          name: "Login",
          params: {
            screenExceed: true,
          },
        });
      }
    });
    SocketioService.eventBus.$on("connection-blocked", () => {
      this.$router.push({
        path: "/",
      });
    });
    let roomNames = ["all"];
    if (this.$store.state.user.isLoggedIn) {
      roomNames.push("1sports" + this.$store.state.user.userId);
      roomNames.push("1sportsstream" + this.$store.state.user.userId);
      await SocketioService.socket.streamRoom({
        roomName: "1sportsstream" + this.$store.state.user.userId,
        accessToken: localStorage.accessToken,
      });
    }
    await SocketioService.socket.joinRooms({
      roomNames: roomNames,
    });
    SocketioService.eventBus.$on("reconnected", async () => {
      console.log("reconnected");
      let roomNames = ["all"];
      if (this.$store.state.user.isLoggedIn) {
        roomNames.push("1sports" + this.$store.state.user.userId);
        roomNames.push("1sportsstream" + this.$store.state.user.userId);
        await SocketioService.socket.streamRoom({
          roomName: "1sportsstream" + this.$store.state.user.userId,
          accessToken: localStorage.accessToken,
        });
      }
      await SocketioService.socket.joinRooms({
        roomNames: roomNames,
      });
    });
    if (!this.$store.state.language.language) {
      if (navigator.language.substring(0, 2) == "en") {
        this.$store.commit("language/setLanguage", "en");
      } else if (navigator.language.substring(0, 2) == "hi") {
        this.$store.commit("language/setLanguage", "hi");
      } else if (navigator.language.substring(0, 2) == "gu") {
        this.$store.commit("language/setLanguage", "gu");
      } else if (navigator.language.substring(0, 2) == "te") {
        this.$store.commit("language/setLanguage", "te");
      } else if (navigator.language.substring(0, 2) == "ta") {
        this.$store.commit("language/setLanguage", "ta");
      } else if (navigator.language.substring(0, 2) == "kn") {
        this.$store.commit("language/setLanguage", "kn");
      }
    }
    this.$i18n.locale = this.$store.state.language.language;
    if (window)
      window.addEventListener("click", () => {
        if (this.$router.currentRoute.path.includes("/livestream/")) {
          if (this.$nosleep) this.$nosleep.disable();
          this.$nosleep = new NoSleep();
          this.$nosleep.enable();
        }
      });
    else {
      console.log("no window 1");
    }
    if (document) {
      document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
        once: true,
      });
    } else {
      console.log("no document 1");
    }
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    } else {
      console.log("no navigator 1");
    }
    this.loading = true;
    let installPrompt;
    if (window) {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        installPrompt = e;
        if (!this.$store.state.dialog.updateDialog) {
          this.$store.commit("dialog/setAppInstallDialog", true);
          this.dialog = true;
        }
        this.installer = () => {
          this.dialog = false;
          installPrompt.prompt();
          installPrompt.userChoice.then((result) => {
            if (result.outcome === "accepted") {
              this.$store.commit("dialog/setAppInstallDialog", false);
              console.log("Install accepted!");
            } else {
              console.log("Install denied!");
            }
          });
        };
      });
    } else {
      console.log("no window 2");
    }
  },
  methods: {
    async appUpdateUI(e) {
      this.$store.commit("dialog/setUpdateDialog", true);
      this.registration = e.detail;
      await this.$dialog
        .confirm({
          text: "Update available, please refresh.",
          actions: {
            true: "Refresh",
          },
        })
        .then((res) => {
          if (res == true) {
            if (this.registration || this.registration.waiting) {
              this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
            }
          } else {
            this.$store.commit("dialog/setUpdateDialog", false);
          }
        });
    },
    closeModal() {
      this.dialog = false;
      this.$store.commit("dialog/setAppInstallDialog", false);
    },
  },
  watch: {
    "$store.state.dialog.updateDialog": {
      async handler() {
        if (this.$store.state.dialog.updateDialog && this.dialog) {
          this.dialog = false;
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/Scss/_main.scss";
</style>
