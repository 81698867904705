<template>
  <div>
    <div class="d-flex justify-center" v-if="loading">
      <circularLoader />
    </div>
    <div v-else class="header-section">
      <v-app-bar color="black" fixed class="drawer-icon">
        <router-link to="/" class="d-flex">
          <img src="@/assets/images/logo.png" alt="Logo" height="35px" />
        </router-link>
        <div
          class="font-22 text-center medium"
          style="color: #b6842d; text-transform: uppercase"
        >
          {{ title }}
        </div>
        <div @click.stop="drawer = !drawer" class="hamburger">
          <img src="@/assets/images/hamburger.svg" alt="hamburger" />
        </div>
      </v-app-bar>
      <v-navigation-drawer
        fixed
        right
        top
        temporary
        width="80%"
        style="max-width: 400px"
        class="header-drawer-content"
        v-model="drawer"
      >
        <div class="d-flex my-6 justify-space-between">
          <div class="ml-6 d-flex">
            <img src="@/assets/images/account.svg" alt="account" />
            <div>
              <div class="font-26 ml-4 pt-1">{{ name }}</div>
              <h4
                v-if="planDetails && planDetails.status"
                class="ml-6"
                :class="{
                  'green-txt': planDetails.status == 'active',
                  'red-txt':
                    planDetails.status == 'expired' ||
                    planDetails.status == 'cancelled/refunded',
                }"
              >
                &#9679;
                {{ planDetails.status == "active" ? "Active" : "Expired" }}
                <span v-if="planDetails.status == 'active'"
                  >({{ planDetails.diff }} days remaining)</span
                >
              </h4>
            </div>
          </div>
        </div>
        <v-divider class="divider-color"></v-divider>
        <v-list
          nav
          dense
          v-for="(menu, index) in MainMenus"
          :key="index"
          class="pa-0 ml-4 mt-6"
        >
          <template v-if="index == 1">
            <div class="d-flex">
              <div class="ml-4 mt-1">
                <v-img :src="menu.image" alt="edit" width="24px" />
              </div>
              <switch-locale class="ml-7" style="width: 180px" />
            </div>
          </template>
          <v-list-item-group v-else v-model="group">
            <v-list-item :to="menu.link">
              <div class="ml-2">
                <v-img :src="menu.image" alt="edit" width="24px" />
              </div>
              <v-list-item-title>
                <span class="font-18 ml-7">
                  {{ menu.title }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="footer-nav mt-5 mx-5">
          <v-card
            class="mx-auto my-5 footer-card mx-2"
            max-width="344"
            outlined
          >
            <v-list-item three-line :style="{ display: installBtn }">
              <v-list-item-content>
                <v-list-item-title class="mb-5 d-flex justify-center">
                  {{ $t("sideBar.downloadOurApp") }}
                </v-list-item-title>
                <v-row>
                  <v-col cols="12" sm="12" class="d-flex justify-center">
                    <v-btn
                      class=""
                      outlined
                      color="#b6842d"
                      download
                      @click="installer()"
                    >
                      <v-icon large color="#b6842d"> mdi-android</v-icon>
                      {{ $t("sideBar.installApp") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>

        <div class="bottom"></div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import changePassword from "@/assets/images/changePassword.svg";
import language from "@/assets/images/language.svg";
import iphone from "@/assets/images/iphone.svg";
import logout from "@/assets/images/logout.svg";
import circularLoader from "@/components/circularLoader.vue";
import SwitchLocale from "./switchLocale.vue";
export default {
  props: ["title", "planDetails"],
  components: {
    circularLoader,
    SwitchLocale,
  },
  data() {
    return {
      name: this.$store.state.user.username,
      drawer: false,
      check: false,
      checkHomePage: false,
      group: null,
      MainMenus: [
        {
          image: changePassword,
          title: this.$t("sideBar.changePassword"),
          link: "/resetpassword",
        },
        {
          image: language,
          title: this.$t("sideBar.languages"),
          link: "/",
        },
        {
          image: iphone,
          title: this.$t("sideBar.installApp") + " (Iphone)",
          link: "/installPwaIos",
        },
        {
          image: logout,
          title: this.$t("sideBar.logout"),
          link: "/login",
        },
      ],
      installBtn: "none",
      installer: undefined,
      loading: false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  async created() {
    this.loading = true;
    let installPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installBtn = "block";
    });

    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!");
        } else {
          console.log("Install denied!");
        }
      });
    };
    this.loading = false;
  },
  mounted() {
    if (this.$router.currentRoute.path === "/") {
      this.checkHomePage = true;
    }
  },
};
</script>
<style lang="scss">
.v-input-field {
  .v-text-field__details {
    display: none !important;
  }
  .v-input__slot {
    margin-bottom: 0px;
    font-weight: 500;
  }
  .v-label {
    font-size: 19px;
    font-weight: 500;
  }
  .v-input {
    font-size: 19px;
    font-weight: 500;
  }
  .v-application .mt-4 {
    margin-top: 0px !important;
  }
  .v-input__control {
    min-height: 0px !important;
  }
  .v-icon.notranslate.mdi.mdi-menu-down.theme--dark {
    display: none;
  }
}

.header-section {
  margin-bottom: 56px;
}
.drawer-icon {
  height: 56px;
  justify-content: space-between !important;
}
.divider-color {
  color: #b6842d !important;
}
.hamburger {
  width: 76px;
  display: flex;
  justify-content: flex-end;
}
.footer-card {
  border-color: #b6842d !important;
}
.ios {
  min-width: 141.92px !important;
}
@media only screen and (min-width: 959px) {
  .header-section {
    margin-bottom: 64px;
  }
}
</style>
