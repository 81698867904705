<template>
  <div>
    <div v-if="loading" class="d-flex justify-center">
      <div v-if="!favorites">
        <circularLoader />
      </div>
    </div>
    <div v-else>
      <div v-if="!playStarted" class="d-flex justify-center">
        <div v-if="!favorites">
          <flashLoader />
        </div>
      </div>
      <div
        style="width: 100%; position: relative"
        :class="{ httt: !favorites }"
      >
        <div
          :id="'livetvPlayer' + index"
          :class="{
            livetvStyle: !favorites,
          }"
        ></div>
        <button class="iphoneToolBar" @click="controlAudio()">
          <v-icon v-if="muted">mdi-volume-mute</v-icon>
          <v-icon v-if="!muted">mdi-volume-high</v-icon>
        </button>
        <div v-if="displaycommentaryText" class="commentaryText">
          {{ commentaryText }}
        </div>
        <marquee v-if="messageText" class="messageText">
          {{ messageText }}
        </marquee>
      </div>
    </div>
  </div>
</template>

<script>
import { Room, RoomEvent } from "livekit-client";
import circularLoader from "../components/circularLoader.vue";
import flashLoader from "../components/flashLoader.vue";
import * as SocketioService from "@/services/socketioService.js";
import router from "../router/index";
import gameService from "@/services/gameService";
const ballStart = require("@/assets/sounds/ballStart.mp3");
const zero = require("@/assets/sounds/zero.mp3");
const one = require("@/assets/sounds/one.mp3");
const two = require("@/assets/sounds/two.mp3");
const three = require("@/assets/sounds/three.mp3");
const four = require("@/assets/sounds/four.mp3");
const six = require("@/assets/sounds/six.mp3");
const wicket = require("@/assets/sounds/wicket.mp3");
const noball = require("@/assets/sounds/noball.mp3");
const wide = require("@/assets/sounds/wide.mp3");
const freehit = require("@/assets/sounds/freehit.mp3");
const playersAreComing = require("@/assets/sounds/playersAreComing.mp3");
const heavyRain = require("@/assets/sounds/heavyRain.mp3");
const rain = require("@/assets/sounds/rain.mp3");
const coversAreComing = require("@/assets/sounds/coversAreComing.mp3");
const batsmanInjured = require("@/assets/sounds/batsmanInjured.mp3");
// const bowlerInjured = require("@/assets/sounds/bowlerInjured.mp3");
const appeal = require("@/assets/sounds/appeal.mp3");
const thirdUmpire = require("@/assets/sounds/thirdUmpire.mp3");
const notOut = require("@/assets/sounds/notout.mp3");
const inTheAir = require("@/assets/sounds/ballInTheAir.mp3");
const bowlerStopped = require("@/assets/sounds/bowlerStopped.mp3");
const checking = require("@/assets/sounds/checking.mp3");
const fastBowler = require("@/assets/sounds/fastBowler.mp3");
const spinBowler = require("@/assets/sounds/spinner.mp3");
const breakSound = require("@/assets/sounds/break.mp3");
const review = require("@/assets/sounds/review.mp3");
const overthrow = require("@/assets/sounds/overthrow.mp3");
// const catchDrop = require("@/assets/sounds/catchDrop.mp3");
const commenatry = require("@/assets/sounds/commentary.mp3");
export default {
  components: { circularLoader, flashLoader },
  props: ["index", "streams", "favorites", "count", "id"],
  name: "LiveTv",
  data() {
    return {
      loading: false,
      room: null,
      wsURL: process.env.VUE_APP_ENV_LIVEKIT_URL,
      token: this.streams.token,
      commentaryText: "",
      messageText: this.streams.message,
      displaycommentaryText: false,
      audio: null,
      mute: false,
      playStarted: false,
      inter: undefined,
      inter2: undefined,
      isIos: false,
      muted: false,
      gameId: undefined,
      audioTrack: undefined,
    };
  },
  async mounted() {
    this.loading = true;
    this.gameId = this.$route.params.id;
    this.checkIos();
    this.loading = false;
    SocketioService.eventBus.$on("muteLiveTvAudio", (index) => {
      this.muteAudio(index);
    });
    await this.connect();
    await this.joinSockets();
    await this.commentaryService();
    await this.logOutGame();
    await this.gameLogsJoin();
    await this.gameUpdateService();
    this.socketReconnection();
  },
  methods: {
    async connect() {
      this.room = new Room();
      this.room.prepareConnection(this.wsURL, this.token);
      this.room
        .on(RoomEvent.TrackSubscribed, (track, RemoteTrackPublication) => {
          this.handleTrackSubscribed(track, RemoteTrackPublication);
        })
        .on(RoomEvent.TrackUnsubscribed, () => {
          this.handleTrackUnsubscribed();
        })
        .on(RoomEvent.Disconnected, () => {
          this.handleDisconnect();
        });
      await this.room.connect(this.wsURL, this.token);
    },
    handleTrackSubscribed(track, publication) {
      const element = track.attach();
      if (track.kind === "audio") {
        this.audioTrack = track;
        if (this.audioTrack && this.favorites) {
          this.audioTrack.detach();
          this.muted = true;
        }
      }
      if (element.tagName === "VIDEO") {
        console.log("video track", track);
        publication.setVideoQuality(2);
        element.setAttribute("id", "playerDiv" + this.index);
        element.setAttribute(
          "style",
          `position: relative; background: black !important; width: 100%; height: 100%;`
        );
        element.setAttribute("disablePictureInPicture", "");
        element.setAttribute("autoplay", "");
        element.setAttribute("controls", true);
        element.setAttribute("playsinline", "");
        if (this.favorites && this.count == 1) {
          element.setAttribute("class", "favorite1");
        } else if (this.favorites && this.count == 2) {
          element.setAttribute("class", "favorite2");
        } else if (this.favorites && this.count == 3) {
          element.setAttribute("class", "favorite3");
        } else if (this.favorites && this.count == 4) {
          element.setAttribute("class", "favorite4");
        }
        document
          .getElementById("livetvPlayer" + this.index)
          .appendChild(element);
        this.playStarted = true;
      }
      if (document.querySelector("#playerDiv" + this.index)) {
        document
          .querySelector("#playerDiv" + this.index)
          .addEventListener("click", (e) => e.preventDefault());
      }
    },
    handleDisconnect() {
      if (document.getElementById("playerDiv" + this.index)) {
        const video = document.getElementById("playerDiv" + this.index);
        if (video) {
          video.remove();
        }
      }
      if (this.audioTrack) {
        this.audioTrack.detach();
      }
      this.playStarted = false;
    },
    handleTrackUnsubscribed() {
      if (document.getElementById("playerDiv" + this.index)) {
        const video = document.getElementById("playerDiv" + this.index);
        if (video) {
          video.remove();
        }
      }
      if (this.audioTrack) {
        this.audioTrack.detach();
      }
      this.playStarted = false;
    },
    async gameLogsJoin() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.$route.params.id,
          event: "join",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async joinSockets() {
      if (this.favorites) {
        await SocketioService.socket.joinRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.joinRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
    async logOutGame() {
      SocketioService.eventBus.$on("logOut", async (data) => {
        router.push("/");
      });
    },
    async gameLogsLeft() {
      try {
        await gameService.postUserGameLogs({
          gameId: this.favorites ? this.id : this.gameId,
          event: "left",
        });
      } catch (error) {
        console.log(error);
      }
    },
    async commentaryService() {
      SocketioService.eventBus.$on("commentary", async (data) => {
        console.log("commentary", data);
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.text && data.com !== "Clear") {
            this.displaycommentaryText = true;
            this.commentaryText = data.com;
          } else {
            this.commentaryText = "";
            this.displaycommentaryText = false;
          }
          if (data.sound && !this.muted) {
            if (this.audio) {
              this.audio.pause();
            }
            if (data.com == "0 Runs") this.audio = new Audio(zero);
            else if (data.com == "1 Run") this.audio = new Audio(one);
            else if (data.com == "2 Runs") this.audio = new Audio(two);
            else if (data.com == "3 Runs") this.audio = new Audio(three);
            else if (data.com == "4 Runs") this.audio = new Audio(four);
            else if (data.com == "6 Runs") this.audio = new Audio(six);
            else if (data.com == "Wicket") this.audio = new Audio(wicket);
            else if (data.com == "No Ball") this.audio = new Audio(noball);
            else if (data.com == "Wide") this.audio = new Audio(wide);
            else if (data.com == "Free Hit") this.audio = new Audio(freehit);
            else if (data.com == "Ball Start")
              this.audio = new Audio(ballStart);
            else if (data.com == "Players Are Coming")
              this.audio = new Audio(playersAreComing);
            else if (data.com == "Heavy Rain")
              this.audio = new Audio(heavyRain);
            else if (data.com == "Rain") this.audio = new Audio(rain);
            else if (data.com == "Covers Are Coming")
              this.audio = new Audio(coversAreComing);
            else if (data.com == "Batsman Injured")
              this.audio = new Audio(batsmanInjured);
            // else if (data.com == "Bowler Injured")
            //   this.audio = new Audio(bowlerInjured);
            else if (data.com == "Appeal") this.audio = new Audio(appeal);
            else if (data.com == "Third Umpire")
              this.audio = new Audio(thirdUmpire);
            else if (data.com == "Not Out") this.audio = new Audio(notOut);
            else if (data.com == "In The Air") this.audio = new Audio(inTheAir);
            else if (data.com == "Bowler Stop")
              this.audio = new Audio(bowlerStopped);
            else if (data.com == "Checking") this.audio = new Audio(checking);
            else if (data.com == "Fast Bowler")
              this.audio = new Audio(fastBowler);
            else if (data.com == "Spin Bowler")
              this.audio = new Audio(spinBowler);
            else if (data.com == "Break") this.audio = new Audio(breakSound);
            else if (data.com == "Review") this.audio = new Audio(review);
            else if (data.com == "Overthrow") this.audio = new Audio(overthrow);
            // else if (data.com == "Catch Drop")
            //   this.audio = new Audio(catchDrop);
            else if (data.com == "Commentary")
              this.audio = new Audio(commenatry);
            else if (data.com == "Clear") this.audio = new Audio("");
            else {
              return;
            }
            if (
              data.com !== "Clear" &&
              data.com !== "Break" &&
              data.com !== "Good Night" &&
              data.com !== "Match Delayed" &&
              data.com !== "Wet Outfield" &&
              data.com !== "Review"
            )
              this.audio.play();
          }
        }
      });
    },
    async gameUpdateService() {
      SocketioService.eventBus.$on("updateGameData", async (data) => {
        if (
          (!this.favorites && data.id == this.$route.params.id) ||
          data.id == this.id
        ) {
          if (data.message) {
            this.messageText = data.message;
          }
          if (data.message == "") {
            this.messageText = "";
          }
        }
      });
    },
    socketReconnection() {
      SocketioService.eventBus.$on("reconnected", async () => {
        console.log("reconnected");
        this.joinSockets();
      });
    },
    checkIos() {
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      ) {
        this.isIos = true;
      }
    },
    muteAudio(index) {
      if (this.audioTrack && index != this.index) {
        this.muted = true;
        this.audioTrack.detach();
      }
    },
    async leaveSockets() {
      if (this.favorites) {
        await SocketioService.socket.leaveRooms({
          roomNames: ["commentaryRoom-" + this.id, this.id],
        });
      } else {
        await SocketioService.socket.leaveRooms({
          roomNames: [
            "commentaryRoom-" + this.$route.params.id,
            this.$route.params.id,
          ],
        });
      }
    },
    controlAudio() {
      if (this.favorites) {
        if (this.muted) {
          SocketioService.eventBus.$emit("muteLiveTvAudio", this.index);
          this.muted = false;
          this.audioTrack.attach();
        } else {
          this.muted = true;
          this.audioTrack.detach();
        }
      } else {
        if (!this.muted && this.audioTrack) {
          this.muted = !this.muted;
          this.audioTrack.detach();
        } else if (this.muted && this.audioTrack) {
          this.muted = !this.muted;
          this.audioTrack.attach();
        }
      }
    },
  },
  async beforeDestroy() {
    await this.gameLogsLeft();
    if (this.inter) {
      clearInterval(this.inter);
    }
    if (this.inter2) {
      clearInterval(this.inter2);
    }
    await this.leaveSockets();
    this.room.disconnect();
  },
};
</script>

<style scoped>
.livetvStyle {
  height: 100vh;
}
.iphoneToolBar {
  position: absolute;
  bottom: 50px;
  right: 10px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.52);
  display: inline;
}
</style>
